.dashboard-component {
	display: flex;
	flex-wrap: nowrap;
	overflow: hidden;

	.schedules-container {
		flex: 1 1 70%;
		padding: 0 15px 15px 15px;

		.meta-data {
			height: fit-content;
		}
		.schedules {
			height: 100%;
			width: 100%;

			display: flex;
			flex-direction: column;
			.title {
				flex: 0 1 50px;
				display: flex;
				span {
					font-size: 1.2rem;
					font-weight: 500;
					margin: auto;
				}
			}
			.calendar {
				width: 100%;
				flex: 0 1 auto;
				height: fit-content;
				&.Intelli_scroll::-webkit-scrollbar {
					width: 5px;
				}
			}
			> .timeline {
				// border-top: 1px solid rgba(0, 0, 0, 0.2);
				flex: 1 1;
				padding: 0 7px;

				&.Intelli_scroll::-webkit-scrollbar {
					width: 5px;
				}
			}
		}
	}
	.timelogs-container {
		flex: 0 1 500px;
		border-right: 1px solid $softGrey;
	}
}

.basepage-component {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	overflow: hidden;
	height: 100%;
	flex: 0 1 100%;
	.toolbar {
		flex: 0 1 70px;
		height: 70px;
		// border: 1px solid $softGrey;

		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		.fields {
			flex: 1 1;
			display: flex;
			padding: 0 15px;
			> div {
				margin: auto 15px auto 0;
			}
		}

		.buttons {
			flex: 0 1 15%;
			display: flex;
			padding: 0 15px;
			justify-content: flex-end;
			> div {
				margin: auto 0 auto 15px;
			}
		}

		.Intelli_datepicker {
			.MuiOutlinedInput-root {
				height: 36px;
			}
		}

		.select__control {
			min-height: 2.4rem !important;
			max-height: 2.4rem !important;
			height: 32px;
			.select__value-container {
				height: 100%;
				@extend .Intelli_scroll;
			}
			&--is-focused {
				border-color: $colorPrimary;
				box-shadow: 0 0 0 1px $colorPrimary;
			}
		}
	}

	.main {
		flex: 1 1;
		height: 50%;
		padding: 0 15px 15px 15px;
	}

	.schedules-container {
		width: 100%;
		flex: 1 1;
		display: flex;
		flex-direction: column;
		.calendar {
			width: 100%;
			flex: 0 1 auto;
			height: fit-content;
			&.Intelli_scroll::-webkit-scrollbar {
				width: 5px;
			}
		}
		> .intelliTableRow {
			flex: 1 1;

			&.Intelli_scroll::-webkit-scrollbar {
				width: 5px;
			}
		}
	}
}

.month-component {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.titles {
		flex: 0 1 30px;
		display: flex;
		flex-wrap: nowrap;

		.title {
			flex: 0 1 calc(100% / 7);
			display: flex;
			&:first-child {
				border: 1px solid $softGrey;
			}
			border-top: 1px solid $softGrey;
			border-right: 1px solid $softGrey;
			border-bottom: 1px solid $softGrey;

			height: 100%;
			> span {
				margin: auto;
				color: $fontBlack;
			}
		}
	}
	.calendar-month {
		flex: 1 1;
		height: 50%;
		width: 100%;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		&__day {
			flex: 0 1 calc(100% / 7);
			width: calc(100% / 7);
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			position: relative;
			padding: 6px;
			&:hover {
				background-color: #e3f2fd !important;
			}
			&:nth-of-type(7n-6) {
				border-right: 1px solid $softGrey;
				border-left: 1px solid $softGrey;
				border-bottom: 1px solid $softGrey;
			}
			border-right: 1px solid $softGrey;
			border-bottom: 1px solid $softGrey;
			.date {
				flex: 0 1 auto;
				height: fit-content;
				font-weight: 400;
				color: #777777;
				margin-left: 2px;
				font-size: 12px;

				&.today {
					span {
						background-color: #69b5e7;
						border-radius: 50%;
						padding: 5px;
						color: white;
					}
				}
			}

			.items {
				flex: 1 1;
				height: 50%;
				padding: 9px;
				z-index: 10;
				width: 100%;
				@extend .Intelli_scroll;
				.MuiBadge-root {
					width: 100%;
				}

				@media screen and (max-height: 800px) {
					padding: 3px;
				}

				.item_dropped {
					display: flex;
					width: 100%;
					flex-wrap: nowrap;
					border-radius: 0.2em;
					height: 50px;
					// height: 1.4rem;
					margin: 5px 0 0 0;
					cursor: pointer;
					font-size: 0.8rem;
					&:hover {
						opacity: $opacity-item-hover;
						transition: $transition-opacity;
					}

					.siglas {
						flex: 0 1 auto;
						border-radius: 0.2em 0 0 0.2em;
						// padding: 0 0.2em;
						width: 13%;
						text-align: center;
						display: flex;
						.name {
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
							margin: auto;
						}
						i {
							color: white;
							margin: auto;
						}
					}
					> .time {
						flex: 1 1 50%;
						width: 50%;
						text-align: center;
						border-radius: 0 0.2em 0.2em 0;
						display: flex;
						padding: 2px 2px;
						position: relative;
						> .time {
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							margin: auto;
							display: flex;
							flex-direction: column;
							justify-content: space-evenly;
							height: 100%;
              .place {
                flex: 0 1 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                
                > i {
                  margin-right: 3px;
                }

                > span {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-size: 0.65rem;
                  font-weight: bold;
                }
              }
							.text {
								flex: 0 1 auto;
								display: flex;
								span {
									margin: auto;
									width: 100%;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
								}
							}

							@media screen and (max-height: 800px) {
								span {
									font-size: 10px;
								}
							}

							@media screen and (max-width: 1412px) {
								span {
									font-size: 10px;
								}
							}
						}
						.icon {
							position: absolute;
							flex: 0 1 100%;
							width: 100%;
							display: flex;
							padding: 4px 5px;
							i {
								font-size: 13px;
								margin: 0 0 0 auto;
								color: #90a4ae;
								&:hover {
									color: black;
								}
							}
						}
						@media screen and (max-height: 800px) {
							.icon {
								padding: 2px 4px;
								i {
									font-size: 10px;
								}
							}
						}
					}
					> .info-button {
						flex: 0 1 auto;
						border-radius: 0 0.2em 0.2em 0;
						width: 6%;
						text-align: center;
						button {
							font-size: 0.9rem;
						}
					}
				}
			}

			.water-mark {
				height: 100%;
				width: 100%;
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;

				span {
					font-size: 32px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

					padding: 0 3px;
				}

				&.all {
					.quantity,
					.title {
						flex: 0 1 50%;
						display: flex;
					}

					.quantity {
						padding-top: 5px;
						span {
							margin-bottom: auto;
						}
					}

					.title {
						padding-bottom: 5px;

						span {
							margin-top: auto;
							font-size: 20px;
						}
					}
				}

				&.up {
					height: auto;
					flex-direction: row;

					.quantity,
					.title {
						flex: 0 1 auto;
					}
					span {
						font-size: 18px;
						&:nth-child(1) {
							border-top-left-radius: 5px;
							border-bottom-left-radius: 5px;
							color: white;
						}
						&:nth-child(2) {
							color: white;
						}
						&:nth-child(3) {
							border-top-right-radius: 5px;
							border-bottom-right-radius: 5px;
							color: white;
						}
					}
					@media screen and (max-height: 800px) {
						span {
							font-size: 14px;
						}
					}
				}

				@media screen and (max-height: 800px) {
					span {
						font-size: 22px;
					}
				}
			}
		}
	}
}

.meta-data {
	flex: 0 1 auto;
	display: flex;
	margin-bottom: 8px;
	flex-wrap: wrap;
	height: fit-content;

	// > .MuiGrid-root {
	// 	height: 19px;
	// }

	.person_name {
		display: flex;
		padding-left: 4px;
		span {
			margin-top: auto;
			font-weight: 500;
		}
	}

	.Intelli_info {
		height: fit-content;
		margin-top: auto;

		.Intelli_icon {
			i {
				font-size: 16px;
			}
		}

		span {
			padding-bottom: 0;
		}
	}
}

.matrix {
	display: flex;
	width: 100%;
	height: 100%;
	flex-wrap: nowrap;
	flex-direction: column;

	.headers {
		flex: 0 1 25px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		height: 25px;

		position: sticky;
		top: 0;
		z-index: 50;

		color: $fontBlack;

		div {
			display: flex;
			background-color: $colorPrimary;
			color: $fontWhite;
			// background-color: #9c9c9c33;
			span {
				margin: auto;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.timelog_in,
		.timelog_out {
			flex: 0 1 91px;
			width: 91px;
			min-width: 91px;
			max-width: 91px;
			border-right: 1px solid white;
		}

		.schedules,
		.planified,
		.times,
		.worked,
		.permissions,
		.hours,
		.extra,
		.absences,
		.day {
			flex: 0 1 calc((100%-182px) / 9);
			width: calc((100%-182px) / 9);
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			padding: 3px;
		}

		.schedules,
		.planified,
		.times,
		.worked,
		.hours,
		.extra,
		.day,
		.absences {
			border-right: 1px solid white;
		}
	}
	.rows {
		flex: 0 1;
		height: 375px;
		font-size: 0.8rem;
		justify-content: space-around;
	}

	.accordion-row {
		height: fit-content;

		height: 52px;

		&:nth-of-type(2n + 1) {
			.MuiAccordionSummary-root {
				background-color: white;
			}
		}
		&:nth-of-type(2n) {
			.MuiAccordionSummary-root {
				background-color: #f3f3f3;
			}
		}

		.MuiAccordionSummary-root {
			padding: 0;
			min-height: auto;
			height: 100%;
		}

		.MuiAccordionSummary-content {
			margin: 0;
			height: 100%;
		}

		.MuiAccordionDetails-root {
			.component_modal {
				width: 100%;
			}
		}

		.MuiCollapse-wrapper {
			border-right: 1px solid $softGrey;
			border-left: 1px solid $softGrey;
		}

		&.Mui-expanded {
			height: fit-content;
			border: 1px solid $colorPrimary;

			.MuiAccordionSummary-root {
				// border-top: 1px solid $softGrey;
				height: 52px;
			}

			+ .MuiAccordion-root {
				.MuiAccordionSummary-root {
					border-top: 1px solid $softGrey;
				}
			}
		}
	}

	.row {
		flex: 1 1 10%;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;

		color: rgb(84, 84, 84);
		font-weight: 400;
		letter-spacing: 0.3px;
		cursor: pointer;
		> div {
			display: flex;
			flex-direction: column;
			span {
				margin: auto;
				text-align: center;
			}
		}

		&:hover {
			background-color: #f3f9ff;
		}

		.timelogPair {
			flex: 0 1 182px;
			width: 182px;
			border-left: 1px solid $softGrey;
			border-right: 1px solid $softGrey;
			border-bottom: 1px solid $softGrey;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			.in,
			.out {
				flex: 0 1 91px;
				width: 91px;
				display: flex;
				padding: 4px 0;
				img {
					width: 50%;
					height: 100%;
					margin: auto;
				}
				transition: 0.2s;

				&:hover {
					img {
						transition: 0.2s;
						border: 1px solid rgba(46, 194, 255, 0.52);
					}
				}
			}

			.in {
				border-right: 1px solid $softGrey;
			}
		}

		.day {
			display: flex;
			flex-direction: row;
			span {
				text-align: center;
			}
		}

		.day,
		.workshifts,
		.times,
		.permissions,
		.hours,
		.extra,
		.absences,
		.planified,
		.worked {
			flex: 0 1 calc((100% - 182px) / 9);
			width: calc((100% - 182px) / 9);
			border-right: 1px solid $softGrey;
			border-bottom: 1px solid $softGrey;
		}
	}

	&.Intelli_scroll::-webkit-scrollbar {
		width: 5px;
	}
}

.wrapperEntityPermissionHistory {
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	flex-wrap: wrap;
	.MuiAccordion-root {
		width: 100%;
		&.MuiAccordion-root.Mui-expanded {
			margin: 0;
		}
		.Mui-expanded {
			margin: 0;
			background-color: #29b6f6;
		}
		.MuiAccordionSummary-root {
			background-color: #29b6f6;
			min-height: 27px;
			color: white;
			border-bottom: 1px solid;
			border-radius: 0.3em;
		}
		.MuiAccordionSummary-content {
			flex-wrap: wrap;
			margin: 0;
			display: table-row;
		}
	}
	.MuiAccordionDetails-root {
		flex-wrap: wrap;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		padding: 0;
		.PositionWrap {
			flex: 0 1 calc(100% - 0.2em);

			&_title {
				padding: 0 0.1em;
				border-bottom: 1px solid rgba(0, 0, 0, 0.07);
				font-size: 0.79em;
			}
			.select__menu-list {
				z-index: 5000;
			}
		}
	}
}
