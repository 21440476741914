.font {
    &_colorPrimary { color: $colorPrimary !important; }
    &_colorSecondary { color: $colorSecondary !important; }

    &_coloroo { color: #000 !important; }
    &_colorff { color: #fff !important; }
    &_color01 { color: $color01 !important; }
    &_color02 { color: $color02 !important; }
    &_color03 { color: $color03 !important; }
    &_color04 { color: $color04 !important; }
    &_color05 { color: $color05 !important; }
    &_color06 { color: $color06 !important; }
    &_color07 { color: $color07 !important; }
    &_color08 { color: $color08 !important; }
    &_color09 { color: $color09 !important; }
    &_color10 { color: $color10 !important; }
    &_color11 { color: $color11 !important; }
    &_color12 { color: $color12 !important; }
    &_color13 { color: $color13 !important; }
    &_color14 { color: $color14 !important; }
    &_color15 { color: $color15 !important; }
    &_color16 { color: $color16 !important; }
    &_color17 { color: $color17 !important; }
    &_color18 { color: $color18 !important; }
    &_color19 { color: $color19 !important; }
    &_color20 { color: $color20 !important; }
    &_color21 { color: $color21 !important; }
    &_color22 { color: $color22 !important; }
    &_color23 { color: $color23 !important; }
    &_color24 { color: $color24 !important; }
    &_color25 { color: $color25 !important; }
    &_color26 { color: $color26 !important; }
    &_colorAuxiliarPrimary { color: $auxiliarPrimary !important; } 
    &_colorAuxiliarMaps { color: $auxiliarMaps !important; } 
    &_colorAuxiliarSecondary { color: $auxiliarSecondary !important; } 
    &_colorAuxiliarBlackList { color: $auxiliarBlackList !important; } 
    &_colorAuxiliarGraphics { color: $auxiliarGraphics !important; } 
    &_colorAuxiliarMasives { color: $auxiliarMasives !important; } 
    &_colorAuxiliarAccept { color: $auxiliarAccept !important; } 
    &_colorAuxiliarProposition { color: $auxiliarPropositions !important; } 
    &_colorAuxiliarChangeMode {color: $auxiliarChangeMode !important}
    &_colorAuxiliarDelete { color: $auxiliarDelete !important; } 
    &_colorAuxiliarFavorites { color: $auxiliarFavorites !important; } 
    &_colorAuxiliarColumnsOrder { color: $auxiliarColumnsOrder !important; } 
    &_colorAuxiliarDoorOpen { color: $auxiliarDoorOpen !important; } 
    &_colorAuxiliarDoorClose { color: $auxiliarDoorClose !important; } 
    &_colorAuxiliarMove { color: $auxiliarMove !important; }
    &_color_INTELLI_ACCESS { color: $colorIntelliAccess !important; } 
    &_color_INTELLI_GUEST { color: $colorIntelliGuest !important; } 
    &_color_INTELLI_CAMERAS { color: $colorIntelliCameras !important; } 
    &_color_INTELLI_LIGHTS { color: $colorIntelliLights !important; } 
    &_color_INTELLI_ALARMS { color: $colorIntelliAlarms !important; } 
    
    // Variables con colores
    // Modos de Verificación
    &_verified { color: #89c862 !important; font-weight: 500 !important; }
    &_notVerified { color: $colorIntelliAlarms !important; font-weight: 500 !important; }
    &_disabled { color: rgba(255, 145, 10, 1) !important; font-weight: 500 !important; } 
    &_delay { color: $auxiliarPrimary !important; font-weight: 500 !important;}
    &_disconnected { color: $auxiliarBlackList_t50 !important; font-weight: 500 !important; }

    // Tamaños
    &_size01 { font-size: .1em; }
    &_size02 { font-size: .2em; }
    &_size03 { font-size: .3em; }
    &_size04 { font-size: .4em; }
    &_size05 { font-size: .5em; }
    &_size06 { font-size: .6em; }
    &_size07 { font-size: .7em; }
    &_size08 { font-size: .8em; }
    &_size09 { font-size: .9em; }
    &_size10 { font-size: 1em; }
    &_size11 { font-size: 1.1em; }
    &_size12 { font-size: 1.2em; }
    &_size13 { font-size: 1.3em; }
    &_size14 { font-size: 1.4em; }
    &_size15 { font-size: 1.5em; }
    &_size16 { font-size: 1.6em; }
    &_size17 { font-size: 1.7em; }
    &_size18 { font-size: 1.8em; }
    &_size19 { font-size: 1.9em; }
    &_size20 { font-size: 2em; }
    &_size21 { font-size: 2.1em; }
    &_size22 { font-size: 2.2em; }
    &_size23 { font-size: 2.3em; }
    &_size24 { font-size: 2.4em; }
    &_size25 { font-size: 2.5em; }
    &_size26 { font-size: 2.6em; }
    &_size27 { font-size: 2.7em; }
    &_size28 { font-size: 2.8em; }
    &_size29 { font-size: 2.9em; }
    &_size30 { font-size: 3em; }
    &_size40 { font-size: 4em !important; }
    &_size50 { font-size: 5em !important; }
    &_size60 { font-size: 6em !important; }
    &_size70 { font-size: 7em !important; }
    &_size80 { font-size: 8em !important; }
    &_size90 { font-size: 9em !important; }
    &_size100 { font-size: 10em !important; }

    &_w100 { font-weight: 100; }
    &_w200 { font-weight: 200; }
    &_w300 { font-weight: 300; }
    &_w400 { font-weight: 400; }
    &_w500 { font-weight: 500; }
    &_w600 { font-weight: 600; }
    &_w700 { font-weight: 700; }
    &_w800 { font-weight: 800; }
    &_w900 { font-weight: 900; }
    &_wBold { font-weight: bold; }
    &_wBolder { font-weight: bolder; }
    &_wLighter { font-weight: lighter; }
}

.bg {
    &_colorPrimary { background-color: $colorPrimary !important; }
    &_colorPrimaryTA { background-color: $colorPrimaryTA !important; }
    &_colorSecondary { background-color: $colorSecondary !important; }
    &_color01 { background-color: $color01 !important; }
    &_color02 { background-color: $color02 !important; }
    &_color03 { background-color: $color03 !important; }
    &_color04 { background-color: $color04 !important; }
    &_color05 { background-color: $color05 !important; }
    &_color06 { background-color: $color06 !important; }
    &_color07 { background-color: $color07 !important; }
    &_color08 { background-color: $color08 !important; }
    &_color09 { background-color: $color09 !important; }
    &_color10 { background-color: $color10 !important; }
    &_color11 { background-color: $color11 !important; }
    &_color12 { background-color: $color12 !important; }
    &_color13 { background-color: $color13 !important; }
    &_color14 { background-color: $color14 !important; }
    &_color15 { background-color: $color15 !important; }
    &_color16 { background-color: $color16 !important; }
    &_color17 { background-color: $color17 !important; }
    &_color18 { background-color: $color18 !important; }
    &_color19 { background-color: $color19 !important; }
    &_color20 { background-color: $color20 !important; }
    &_color21 { background-color: $color21 !important; }
    &_color22 { background-color: $color22 !important; }
    &_color23 { background-color: $color23 !important; }
    &_color24 { background-color: $color24 !important; }
    &_color25 { background-color: $color25 !important; }
    &_color26 { background-color: $color26 !important; }
    &_colorAuxiliarPrimary { background-color: $auxiliarPrimary !important; }
    &_colorAuxiliarSecondary { background-color: $auxiliarSecondary !important; }
    &_colorAuxiliarBlackList { background-color: $auxiliarBlackList !important; }
    &_colorAuxiliarGraphics { background-color: $auxiliarGraphics !important; }
    &_colorAuxiliarMasives { background-color: $auxiliarMasives !important; }
    &_colorAuxiliarAccept { background-color: $auxiliarAccept !important; }
    &_colorAuxiliarPropositions { background-color: $auxiliarPropositions !important; }
    &_colorAuxiliarDelete { background-color: $auxiliarDelete !important; }
    &_colorAuxiliarFavorites { background-color: $auxiliarFavorites !important; }
    &_colorAuxiliarColumnsOrder { background-color: $auxiliarColumnsOrder !important; }
    &_colorAuxiliarDoorOpen { background-color: $auxiliarDoorOpen !important; }
    &_colorAuxiliarDoorClose { background-color: $auxiliarDoorClose !important; }
    &_colorAuxiliarSidebar { background-color: $auxiliarSidebar !important; }
    &_colorAuxiliarChangeMode {background-color: $auxiliarChangeMode !important;}
    &_colorAuxiliarMove {background-color: $auxiliarMove !important;}
}