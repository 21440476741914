// Tablas
// Tablas
$heightHeader: 2em;
$heightHeaderRow: 1.9em;

$PT: rgba(126, 87, 194, 0.6);
$PR: rgba(72, 116, 151, 0.6);
$place-selected: rgba(85, 204, 63, 0.6);
$place-avaliable: rgba(63, 108, 204, 0.6);
$place-no-avaliable: rgba(204, 63, 63, 0.6);
$place-partial-avaliable: rgba(255, 106, 0, 0.6);
$place-default: rgba(114, 114, 114, 0.6);
$coworking-area: rgb(164 ,197, 149);
$meet-area: rgb(227, 215, 95);

.centerVH {
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
}

.IntelliNotScroll {
	overflow-x: hidden !important;
	overflow-y: hidden !important;
}

.IntelliScroll {
	scrollbar-color: #36b8d8 #2e2d38 !important;
	scrollbar-width: thin !important;

	overflow-x: hidden !important;
	overflow-y: auto !important;

	&::-webkit-scrollbar {
		width: 10px;
		background: #4276bb !important;
	}

	&::-webkit-scrollbar-track {
		background: #2e2d38 !important;
	}

	&::-webkit-scrollbar-thumb {
		background: #4276bb !important;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #555 !important;
	}
}

// Encabezado
.headerTable {
	position: relative;
	height: 100%;
	margin: 0.5rem 0 0.2rem 0;
	background: white;
	position: sticky;

	&:nth-child(n + 1) {
		height: $heightHeader;
		border-radius: 5px;
		border: 1px solid #9e9e9e;
		min-width: 40px;
	}

	&_colLeft {
		height: $heightHeaderRow;
		border-left: 1px solid #9e9e9e;
		cursor: pointer;

		&:hover {
			background-color: #e7e7e7;
		}
	}

	&_button {
		@extend .centerVertical;
		height: $heightHeader;
		padding: 0;
	}

	&_item {
		@extend .intelliTruncate;
		@extend .centerVertical;
		height: $heightHeader;
		font-size: 13px;
		margin-left: 5px;
		width: 100%;
		color: black;
		font-weight: 500;
	}

	&_iconOrderBy {
		margin-top: 6px;
	}

	&_iconOrderByA {
		margin: 6px;
		color: black;
	}

	&_checkboxList {
		padding: 0 !important;
		margin-left: 7px !important;
	}

	&_setting {
		// margin: $margin05 0px;
    // background:  white;
    // z-index: 50;

    position: relative;
    height: 100%;
    margin: $margin05 0px;
    background: white;
    z-index: 50;
    position: sticky;
    top: 170px;

    &:nth-child(n + 1) {
      // height: 35px;
      // border-radius: 5px;
      // border: 1px solid #9e9e9e;
      height: $heightHeader;
      border-radius: 5px;
      border: 1px solid #9e9e9e;
      min-width: 40px;
    }
	}
}

.formRow {
	padding: 7px;

	&_container {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
	}

	&_visitsForm {
		&_data {
			position: relative;
			width: calc(100% - 13em) !important;
		}

		&_photos {
			position: relative;
			width: 12rem !important;
		}
	}

	&_persons {
		&_data {
			position: relative;
			width: calc(100% - 15em) !important;
		}

		&_photos {
			position: relative;
			width: 15em !important;
		}
	}

	&_device {
		&_data {
			position: relative;
			width: calc(100% - 7.5em) !important;
		}

		&_photo {
			position: relative;
			width: 7.5em !important;
		}
	}

	.iconExcl {
		position: absolute;
		cursor: pointer;
		width: 20px;
		height: 20px;
		i{
			font-size: 15px;
			color: rgb(203, 180, 2);
		}
	}

	.itemAssingPlaceDrag {
		z-index: 1;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: -15px;
		margin-left: -15px;
		width: 30px;
		height: 30px;
		font-size: 0.8em;
		border-radius: 50%;
		//color: white;
		cursor: pointer;
	  
		&.PT {
		  background: $PT;
		}
		&.PR {
		  background: $PR;
		}
		&.selected {
		  border: 2px dashed;
		}
		&.avaliable {
		  background: $place-avaliable;
		}
		&.no-avaliable {
		  background: $place-no-avaliable;
		}
		&.partial-avaliable {
		  background: $place-partial-avaliable;
		}
		&.default {
		  background: $place-default;
		}
	  }


	  .available-places {
		min-height: 355px;
		max-height: 355px;
		border: 1px solid #c4c4c4;
		border-radius: 7px;
		margin: 4px;
		position: relative;
		background-color: white;
		min-width: 805.53px;
		display: flex;
		flex-direction: column;
		overflow: auto;
    width: 100%;
    height: 54vh;
    max-height: 392px;
	  align-items: center;
    
		.plan {
		  position: relative;
		  background-size: contain;
		  background-repeat: no-repeat;
		  background-position-y: top;
		  background-position-x: left;
		  width: 805.53px !important;
		  height: 382px !important;
		  min-height: 382px;
		  max-height: 382px;
		  min-width: 805.53px;
		  max-width: 805.53px;
	  
		  @supports (object-fit: contain) {
			img {
			  position: relative;
			  width: 100% !important;
			  height: 100% !important;
			  object-fit: contain;
			  object-position: center center;
			}
		  }
		}
		.default-plan {
		  height: 382px;
		  border-radius: 7px;
		  margin: 4px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  width: 100%;
	  
		  img {
			width: 270px;
		  }
		}
	  }

  .plan-places-legend {
    // min-height: 382px;
    // max-height: 382px;
    position: relative;
    margin: 4px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px;
    flex-wrap: wrap;
    justify-content: center;

	  .legend-item {
      width: fit-content;
      display: flex;
      margin: 0 6px;
  
		&-sub {
		  width: 27px;
		  height: 27px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  font-size: .8rem;
		  position: relative;
		  
		  &.coworking-area::before {
			content: "";
			position: absolute;
			width: 19px;
			height: 19px;
			border-radius: 50%;
			background: $coworking-area;
		  }
		  &.meet-area::before {
			content: "";
			position: absolute;
			width: 19px;
			height: 19px;
			border-radius: 50%;
			background: $meet-area;
		  }
  
		  &.place-available::before {
			content: "";
			position: absolute;
			width: 19px;
			height: 19px;
			border-radius: 50%;
			background: $place-avaliable;
		  }
      &.place-partial-available::before {
        content: "";
        position: absolute;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: $place-partial-avaliable;
        }
		  &.place-no-available::before {
			content: "";
			position: absolute;
			width: 19px;
			height: 19px;
			border-radius: 50%;
			background: $place-no-avaliable;
		  }
		  &.place-selected::before {
			content: "";
			position: absolute;
			width: 19px;
			height: 19px;
			border-radius: 50%;
			border: 2px dashed;
		  }
		  &.place-others::before {
			content: "";
			position: absolute;
			width: 19px;
			height: 19px;
			border-radius: 50%;
			background: $place-default;
		  }
		}
  
		&-text {
		  display: flex;
		  align-items: center;
		  padding-left: 5px;
		  font-size: .8rem;
		  @extend .intelliTruncate_default;
		}
	  }
  }
}

.row_list {
	z-index: 0;
	width: 99.9%;

	> div {
		background: transparent;
	}
}

.itemTableRow {
	z-index: 0;
	border-radius: 5px;
	margin-top: 0;
	margin-bottom: 14px;
	border: 1px solid #29b6f6;

	.formRow {
		background: white;
	}

	&_setting {
		border: 1px solid #000;
		-webkit-box-shadow: 0 0 5px 2px #aaa;
		box-shadow: 0 0 5px 2px #aaa;
	}
}

.itemTableRowA {
	margin-left: 3px;
	margin-bottom: 7px;
	z-index: 0;
	background: white;
}

.rowsGrid {
	height: 30px;
	border-radius: 3px 3px 3px 3px;
	margin: -2px;
	cursor: pointer;
	z-index: -0;
	&:hover {
		z-index: -0;
		background-color: $color17_t25;
	}
}

.rowsGridA {
	height: 30px;
	z-index: -0;
	background-color: #148fbe19;
	// background-color: $color18_t10;
	border-radius: 3px 3px 3px 3px;
	margin: 0.3px;
	cursor: pointer;

	&:hover {
		background-color: #2e8bc740;
		// background-color: $color17_t25;
	}
}

.Intelli-Setting-MuiGrid-grid-xs-1 {
	flex-grow: 0 !important;
	max-width: 4% !important;
	flex-basis: 4% !important;
}

.Intelli-Setting-MuiGrid-grid-xs-11 {
	flex-grow: 0 !important;
	max-width: 96% !important;
	flex-basis: 96% !important;
}

.Intelli-MuiGrid-grid-xs-1 {
	flex-grow: 0 !important;
	max-width: 3% !important;
	flex-basis: 3% !important;
}
.actionsRow {
	position: absolute;
	right: 0px;
}
.itemActions {
	padding: 0.4em 0.7em;
	font-size: 17px;
	color: $fontGrey;
}

.checkboxItem {
	padding: 0;
	margin-left: 10;
	margin-right: 10;
	color: grey;
  }

.intelliTableRow {
	@extend .Intelli_scroll;
	padding-top: 2px;
	margin: 3px 0 0 0;
	border-radius: 0 0 7px 7px;
	border-radius: 5px;
	overflow-x: hidden;
	overflow-y: auto;
	height: 100%;

	.row_list:nth-of-type(odd) {
		background-color: $color18_t05;
	}

	.row_list:nth-of-type(even) {
		background-color: $color18_t10;
	}
	.intelliLoadMore {
		background-color: transparent;
	}
}

.item {
	color: #092d45;
	font-weight: 500;
	margin-left: 2px;
	padding-left: 12px;
	z-index: 0;
	height: 100%;
	white-space: nowrap;
	font-size: 0.8rem;
}

.itemB {
	color: #092d45;
	font-weight: 500;
	margin-left: 2px;
	padding-left: 12px;
	z-index: 0;
	height: 30px;
	white-space: nowrap;
	font-size: 0.8rem;
}

.listMarks {
	p {
	  margin: 0;
	}
	@extend .IntelliScroll;
	max-height: 250px;
	overflow-y: auto;
	overflow-x: hidden;
	> div {
	  margin-bottom: 5px;
	}
  
	// modal Visitado en /guest/visits
  
	.MuiGrid-root:nth-of-type(odd) .MuiGrid-root.rowsGridA {
	  background-color: $color18_t05;
	}
  
	.MuiGrid-root:nth-of-type(even) .MuiGrid-root.rowsGridA {
	  background-color: $color18_t10;
	}
  
	//
  
	.row_list:nth-last-of-type(odd) {
	  background-color: $color18_t05 !important;
	}
	.row_list:nth-last-of-type(even) {
	  background-color: $color18_t10 !important;
	}
  
	&_roleUserModal {
	  @extend .listMarks;
	  max-height: 470px;
	}
  }

  .tooltipTitleColumn {
	top: 0;
	left: 0;
	margin: 5px 0 !important;
	padding: 3px 5px !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	font-size: $fontSize08 !important;
	font-weight: 400 !important;
  }
  
  .tooltipCard_tooltip{
	white-space: pre-wrap !important;
  }