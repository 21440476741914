$fontGrey: rgba(0, 0, 0, 0.2);
$fontWhite: #eaeaea;
$fontBlack: #383838;

//sidebar
$blackIntelli: #2e2d38;
$hoverIcon: grey;
$blueIntelli: #4276b9;

//colors

$softGrey: rgba(0, 0, 0, 0.2);
$hoverGrey: #6b6b6b;
//300
$colorPrimary: #4276b9; //indigo
$colorSecondary: #90a4ae; //bluegrey
$colorAlert: #e57373; //red
$colorWarning: #ffd54f; //yellow
$colorSuccess: #aed581; //lightgreen
$colorEntry: #81c784; //green
$colorTeal: #4db6ac;
$colorGrey: #e0e0e0;

//light for 300
$colorPrimaryLight: #aab6fe; //indigo
$colorSecondaryLight: #c1d5e0; //bluegrey
$colorAlertLight: #ffa4a2; //red
$colorWarningLight: #ffff81; //yellow
$colorSuccessLight: #e1ffb1; //lightgreen
$colorEntryLight: #b2fab4; //green
$colorTealLight: #82e9de;
$colorGreyLight: #ffffff;

//dark for 300
$colorPrimaryDark: #49599a; //indigo
$colorSecondaryDark: #62757f; //bluegrey
$colorAlertDark: #af4448; //red
$colorWarningDark: #c8a415; //yellow
$colorSuccessDark: #7da453; //lightgreen
$colorEntryDark: #519657; //green
$colorTealDark: #00867d;
$colorGreyDarK: #aeaeae;

//calendar
$opacity-item-hover: 0.7;
$transition-opacity: 0.2s;

//entradao salida
$entryColor: #89c862;
$exitColor: #f8737e;

//tables
$color18_t05: #148fbe10;
$color18_t10: #148fbe19;
$color17_t25: #2e8bc740;


:root {
	// --main-blue-color: #00a7df;
	--main-blue-color: #a7ceda;
	--main-marine-color: #363664;
	--main-grey-color: #e6e6e6;
	--second-grey-color: #cccccc;
	--third-grey-color: #abb;
	--main-orange-color: #00a7df;
	--main-green-color: #00ff00;

	//new mode
	--new-orange-color: #f4786c;
	--new-black-primary: #191718;
	--new-black-secondary: #2d2d2d;
	--new-grey-color: #7b7b7b;
	--new-blue-color: #00a8ff;
	--new-white-color: #e6e6e6;
	--header-celeste-color: #57c2e4;
	--header-blue-color: #0070aa;
	--header-marine-color: #0f2f48;

	//dark mode
	--main-black-color: #191c21;
	--second-black-color: #141d26;
	--third-black-color: #243447;
	--forth-black-color: #2e2e2e;
	--main-pink-color: #00a7df;

	// #c51fa9
	--tiny: 1rem;
	--little: 1.2rem;
	--small: 1.5rem;
	--medium: 2rem;
	--large: 3rem;
}

// Variables de colores
// Principales
// $colorPrimary: #092d45;
$colorPrimaryTA: #092d4580;
$colorPrimaryTB: #092d4540;
// $colorSecondary: #29b6f6;
$colorSecondaryTA: #29b6f680;
$colorSecondaryTB: #29b6f640;
$colorPrimaryT4: rgba(9, 45, 69, 0.5);
$colorSecondaryT4: rgba(41, 181, 246, 0.5);

// Paleta especial
$colorEspecial01: #14a5b3;

// Blanco y Negro
$coloroo: #000;
$coloroo_75: #000000c0;
$coloroo_50: #00000080;
$coloroo_25: #00000040;
$coloroo_10: #00000019;
$colorff: #ffffff;
$colorff_75: #ffffffc0;
$colorff_50: #ffffff80;
$colorff_25: #ffffff40;
$colorff_10: #ffffff19;

// Paleta Definida
$color01: #c3cb2e;
$color02: #e2d431;
$color03: #f4e53b;
$color04: #fac940;
$color05: #fbb745;
$color06: #fba153;
$color07: #f98e64;
$color08: #f98e64;
$color09: #f8737e;
$color10: #df6c87;
$color11: #d67399;
$color12: #bb6da4;
$color13: #bb6da4;
$color14: #9f78bb;
$color15: #8680c4;
$color16: #6487c8;
$color17: #2e8bc7;
$color18: #148fbe;
$color19: #14a0c2;
$color20: #14a5b3;
$color21: #14b3ad;
$color22: #14b598;
$color23: #14c08b;
$color24: #89c862;
$color25: #bebebe;
$color26: #ee8532;

// Paleta Definida 75% de transparencia
$color01_t75: #c3cb2ec0;
$color02_t75: #e2d431c0;
$color03_t75: #f4e53bc0;
$color04_t75: #fac940c0;
$color05_t75: #fbb745c0;
$color06_t75: #fba153c0;
$color07_t75: #f98e64c0;
$color08_t75: #f98e64c0;
$color09_t75: #f8737ec0;
$color10_t75: #df6c87c0;
$color11_t75: #d67399c0;
$color12_t75: #bb6da4c0;
$color13_t75: #bb6da4c0;
$color14_t75: #9f78bbc0;
$color15_t75: #8680c4c0;
$color16_t75: #6487c8c0;
$color17_t75: #2e8bc7c0;
$color18_t75: #148fbec0;
$color19_t75: #14a0c2c0;
$color20_t75: #14a5b3c0;
$color21_t75: #14b3adc0;
$color22_t75: #14b598c0;
$color23_t75: #14c08bc0;
$color24_t75: #89c862c0;

// Paleta Definida 50% de transparencia
$color01_t50: #c3cb2e80;
$color02_t50: #e2d43180;
$color03_t50: #f4e53b80;
$color04_t50: #fac94080;
$color05_t50: #fbb74580;
$color06_t50: #fba15380;
$color07_t50: #f98e6480;
$color08_t50: #f98e6480;
$color09_t50: #f8737e80;
$color10_t50: #df6c8780;
$color11_t50: #d6739980;
$color12_t50: #bb6da480;
$color13_t50: #bb6da480;
$color14_t50: #9f78bb80;
$color15_t50: #8680c480;
$color16_t50: #6487c880;
$color17_t50: #2e8bc780;
$color18_t50: #148fbe80;
$color19_t50: #14a0c280;
$color20_t50: #14a5b380;
$color21_t50: #14b3ad80;
$color22_t50: #14b59880;
$color23_t50: #14c08b80;
$color24_t50: #89c86280;

// Paleta Definida 25% de transparencia
$color01_t25: #c3cb2e40;
$color02_t25: #e2d43140;
$color03_t25: #f4e53b40;
$color04_t25: #fac94040;
$color05_t25: #fbb74540;
$color06_t25: #fba15340;
$color07_t25: #f98e6440;
$color08_t25: #f98e6440;
$color09_t25: #f8737e40;
$color10_t25: #df6c8740;
$color11_t25: #d6739940;
$color12_t25: #bb6da440;
$color13_t25: #bb6da440;
$color14_t25: #9f78bb40;
$color15_t25: #8680c440;
$color16_t25: #6487c840;
$color17_t25: #2e8bc740;
$color18_t25: #148fbe40;
$color19_t25: #14a0c240;
$color20_t25: #14a5b340;
$color21_t25: #14b3ad40;
$color22_t25: #14b59840;
$color23_t25: #14c08b40;
$color24_t25: #89c86240;

// Paleta Definida 10% de transparencia
$color01_t10: #c3cb2e19;
$color02_t10: #e2d43119;
$color03_t10: #f4e53b19;
$color04_t10: #fac94019;
$color05_t10: #fbb74519;
$color06_t10: #fba15319;
$color07_t10: #f98e6419;
$color08_t10: #f98e6419;
$color09_t10: #f8737e19;
$color10_t10: #df6c8719;
$color11_t10: #d6739919;
$color12_t10: #bb6da419;
$color13_t10: #bb6da419;
$color14_t10: #9f78bb19;
$color15_t10: #8680c419;
$color16_t10: #6487c819;
$color17_t10: #2e8bc719;
$color18_t10: #148fbe19;
$color19_t10: #14a0c219;
$color20_t10: #14a5b319;
$color21_t10: #14b3ad19;
$color22_t10: #14b59819;
$color23_t10: #14c08b19;
$color24_t10: #89c86219;

// Paleta Definida 5% de transparencia
$color01_t05: #c3cb2e10;
$color02_t05: #e2d43110;
$color03_t05: #f4e53b10;
$color04_t05: #fac94010;
$color05_t05: #fbb74510;
$color06_t05: #fba15310;
$color07_t05: #f98e6410;
$color08_t05: #f98e6410;
$color09_t05: #f8737e10;
$color10_t05: #df6c8710;
$color11_t05: #d6739910;
$color12_t05: #bb6da410;
$color13_t05: #bb6da410;
$color14_t05: #9f78bb10;
$color15_t05: #8680c410;
$color16_t05: #6487c810;
$color17_t05: #2e8bc710;
$color18_t05: #148fbe10;
$color19_t05: #14a0c210;
$color20_t05: #14a5b310;
$color21_t05: #14b3ad10;
$color22_t05: #14b59810;
$color23_t05: #14c08b10;
$color24_t05: #89c86210;

// Auxiliares
$auxiliarPrimary: #0070aa;
$auxiliarSecondary: #29b6f6;
$auxiliarBlackList: #5e5e5e;
$auxiliarGraphics: #c3cb2e;
$auxiliarMasives: #88c965;
$auxiliarMaps:#df3e30;
// $auxiliarDelete: #d44235;
$auxiliarAccept: #89c862;
$auxiliarPropositions: #8681c4;
$auxiliarChangeMode: #fab746;
$auxiliarMove: #29b6f6;
$auxiliarDelete: #f4786c;
$auxiliarFavorites: #fab746;
$auxiliarColumnsOrder: #8681c4;
$auxiliarSidebar: #2e2d38;
$auxiliarDoorOpen: #29b6f6;
$auxiliarDoorClose: #f4786c;

// Auxiliares 75% de Transparencia
$auxiliarPrimary_t75: #0070aac0;
$auxiliarSecondary_t75: #29b6f6c0;
$auxiliarBlackList_t75: #5e5e5ec0;
$auxiliarGraphics_t75: #c3cb2ec0;
$auxiliarMasives_t75: #88c965c0;
// $auxiliarDelete_t75: #d44235c0;
$auxiliarDelete_t75: #f4786cc0;
$auxiliarFavorites_t75: #fab746c0;
$auxiliarColumnsOrder_t75: #8681c4c0;

// Auxiliares 50% de Transparencia
$auxiliarPrimary_t50: #0070aa80;
$auxiliarSecondary_t50: #29b6f680;
$auxiliarBlackList_t50: #5e5e5e80;
$auxiliarGraphics_t50: #c3cb2e80;
$auxiliarMasives_t50: #88c96580;
// $auxiliarDelete_t50: #d4423580;
$auxiliarDelete_t50: #f4786c80;
$auxiliarFavorites_t50: #fab74680;
$auxiliarColumnsOrder_t50: #8681c480;

// Auxiliares 25% de Transparencia
$auxiliarPrimary_t25: #0070aa40;
$auxiliarSecondary_t25: #29b6f640;
$auxiliarBlackList_t25: #5e5e5e40;
$auxiliarGraphics_t25: #c3cb2e40;
$auxiliarMasives_t25: #88c96540;
// $auxiliarDelete_t25: #d4423540;
$auxiliarDelete_t25: #f4786c40;
$auxiliarFavorites_t25: #fab74640;
$auxiliarColumnsOrder_t25: #8681c440;

// Auxiliares 25% de Transparencia
$auxiliarPrimary_t10: #0070aa19;
$auxiliarSecondary_t10: #29b6f619;
$auxiliarBlackList_t10: #5e5e5e19;
$auxiliarGraphics_t10: #c3cb2e19;
$auxiliarMasives_t10: #88c96519;
// $auxiliarDelete_t10: #d4423519;
$auxiliarDelete_t10: #f4786c19;
$auxiliarFavorites_t10: #fab74619;
$auxiliarColumnsOrder_t10: #8681c419;

// Colores de Modulos Intelli-Next
$colorIntelliAccess: #89c862;
$colorIntelliAccessTA: #89c86240;
$colorIntelliAccessTB: #89c86280;

$colorIntelliTime: #25e6e6;
$colorIntelliTimeTA: #25e6e640;
$colorIntelliTimeTB: #25e6e680;

// $colorIntelliGuest: #863760;
$colorIntelliGuest: #14a0c2;
$colorIntelliGuestTA: #14a0c280;
$colorIntelliCameras: #25e6e6;
$colorIntelliCamerasTA: #25e6e640;
$colorIntelliCamerasTB: #25e6e680;
$colorIntelliLights: #f4cf3b;
$colorIntelliLightsTA: #f4cf3b40;
$colorIntelliLightsTB: #f4cf3b80;
$colorIntelliAlarms: #f8737e;
$colorIntelliAlarmsTA: #f8737e40;
$colorIntelliAlarmsTB: #f8737e80;

$colorActive: green;
$colorInactive: red;
$colorDisabled: grey;

// Fuentes
$fontSize02: 0.2em;
$fontSize03: 0.3em;
$fontSize04: 0.4em;
$fontSize05: 0.5em;
$fontSize055: 0.55em;
$fontSize06: 0.6em;
$fontSize065: 0.65em;
$fontSize07: 0.7em;
$fontSize075: 0.75em;
$fontSize08: 0.8em;
$fontSize085: 0.85em;
$fontSize09: 0.9em;
$fontSize095: 0.95em;
$fontSize10: 1em;
$fontSize11: 1.1em;
$fontSize12: 1.2em;
$fontSize125: 1.25em;
$fontSize13: 1.3em;
$fontSize14: 1.4em;
$fontSize15: 1.5em;
$fontSize16: 1.6em;
$fontSize17: 1.7em;
$fontSize18: 1.8em;
$fontSize19: 1.9em;
$fontSize20: 2em;
$fontSize21: 2.1em;
$fontSize22: 2.2em;
$fontSize23: 2.3em;
$fontSize235: 2.35em;
$fontSize24: 2.4em;
$fontSize25: 2.5em;
$fontSize255: 2.55em;
$fontSize26: 2.6em;
$fontSize27: 2.7em;
$fontSize28: 2.8em;
$fontSize29: 2.9em;
$fontSize30: 3em;
$fontSize31: 3.1em;
$fontSize32: 3.2em;
$fontSize33: 3.3em;
$fontSize34: 3.4em;
$fontSize35: 3.5em;
$fontSize40: 4em;
$fontSize41: 4.1em;
$fontSize42: 4.2em;
$fontSize43: 4.3em;
$fontSize44: 4.4em;
$fontSize45: 4.5em;
$fontSize46: 4.6em;
$fontSize47: 4.7em;
$fontSize48: 4.8em;
$fontSize49: 4.9em;
$fontSize50: 5em;

// Iconos
$iconSize10: 1em;
$iconSize11: 1.1em;
$iconSize12: 1.2em;
$iconSize13: 1.3em;
$iconSize14: 1.4em;
$iconSize15: 1.5em;
$iconSize16: 1.6em;
$iconSize17: 1.7em;
$iconSize18: 1.8em;
$iconSize19: 1.9em;
$iconSize20: 2em;

// Tablas
$heightHeader: 2em;
$heightHeaderRow: 1.9em;

// Margenes
$margin01: 0.1em;
$margin02: 0.2em;
$margin03: 0.3em;
$margin035: 0.35em;
$margin04: 0.4em;
$margin045: 0.45em;
$margin05: 0.5em;
$margin055: 0.55em;
$margin06: 0.6em;
$margin065: 0.65em;
$margin07: 0.7em;
$margin08: 0.8em;
$margin09: 0.9em;
$margin095: 0.95em;
$margin10: 1em;
$margin11: 1.1em;
$margin12: 1.2em;
$margin13: 1.3em;
$margin14: 1.4em;
$margin15: 1.5em;
$margin20: 2em;
$margin25: 2.5em;
$margin30: 3em;
$margin40: 4em;
$margin50: 5em;

// Padding
$padding01: 0.1em;
$padding02: 0.2em;
$padding03: 0.3em;
$padding04: 0.4em;
$padding045: 0.45em;
$padding05: 0.5em;
$padding055: 0.55em;
$padding06: 0.6em;
$padding065: 0.65em;
$padding07: 0.7em;
$padding08: 0.8em;
$padding09: 0.9em;
$padding10: 1em;
$padding11: 1.1em;
$padding12: 1.2em;
$padding13: 1.3em;
$padding14: 1.4em;
$padding15: 1.5em;
