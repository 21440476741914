.hoverPrimary,
.hoverSecondary,
.hoverAlert,
.hoverWarning,
.hoverSuccess,
.hoverWhite,
.hoverPrimaryLight {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	border: 1px solid #ffffff40;
	position: relative;
	border-radius: 8px;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	-ms-border-radius: 16px;
	-o-border-radius: 16px;
	filter: brightness(90%);

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 20%;
		height: 100%;
		z-index: 0;
		opacity: 0;
		background-color: #49599a;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
		border-radius: 8px;
	}

	&:hover::before {
		opacity: 0.9;
		width: 100%;
	}
}

.hoverSecondary {
	&:before {
		background-color: $colorSecondaryDark;
	}
}

.hoverAlert {
	&:before {
		background-color: $colorAlertDark;
	}
}

.hoverWarning {
	&:before {
		background-color: $colorWarningDark;
	}
}

.hoverSuccess {
	&:before {
		background-color: $colorSuccessDark;
	}
}

.hoverWhite {
	&:before {
		background-color: rgba(19, 119, 148, 0.3);
	}
}

.hoverPrimaryLight {
	&:before {
		background-color: #6c8fbd;
	}
}

.intelliButtonBase {
	// height: 2rem;
	width: fit-content;
	border-radius: 8px !important;
	border: 1px solid #ffffff40;
	font-size: 11.2px;
	height: 28px;

	i {
		font-size: 18px;
	}

	> div {
		z-index: 5;
	}

	.text {
		margin: 0 8px 0 10px;
	}
	.icon {
		margin: 0 4px 0 0;
	}

	&.primary {
		background-color: $colorPrimary;

		&:nth-child(n + 1) {
			color: white;
		}
		@extend .hoverPrimary;
	}

	&.secondary {
		background-color: $colorSecondary;

		&:nth-child(n + 1) {
			color: white;
		}
		@extend .hoverSecondary;
	}

	&.alert {
		background-color: $colorAlert;

		&:nth-child(n + 1) {
			color: white;
		}
		@extend .hoverAlert;
	}

	&.warning {
		background-color: $colorWarning;

		&:nth-child(n + 1) {
			color: white;
		}
		@extend .hoverWarning;
	}

	&.success {
		background-color: $colorSuccess;

		&:nth-child(n + 1) {
			color: white;
		}
		@extend .hoverSuccess;
	}

	&.colorDisabled {
		background-color: $softGrey;
		i {
			color: $fontBlack;
		}
	}

	//baseform_btn

	&.white {
		&:nth-child(n + 1) {
			color: black;
			border: 0.6px #c4c4c4 solid;
			border-radius: 5px;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			-ms-border-radius: 5px;
			-o-border-radius: 5px;
		}
		> .icon {
			color: #ffffff;
			border-radius: 50%;
			height: 1.3rem;
			width: 1.3rem;
			border-radius: 50%;
			i {
				font-size: 1rem;
				margin-top: 2px;
			}
		}
		@extend .hoverWhite;
	}

	&.colorSuccess {
		> .icon {
			color: white;
			background-color: $colorSuccess;
		}
	}

	&.colorAlert {
		> .icon {
			color: white;
			background-color: $colorAlert;
		}
	}

	&.colorSecondary {
		> .icon {
			color: white;
			background-color: $colorSecondary;
		}
	}

	&.colorPrimary {
		> .icon {
			color: white;
			background-color: none;
		}
	}
}
.Intelli_modal {
  .MuiDialog-container {
    overflow: auto;
  }
	.MuiDialog-paper {
		//overflow: visible;
	}
	.MuiDialogTitle-root {
		background-color: $blueIntelli;
		color: $fontWhite;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		height: 55px;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.5),
			0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
		border-bottom: 1px solid white;
		h2 {
			font-size: 16px;
		}
	}
	.MuiDialogContent-root {
		overflow: visible;
		padding: 12px 9.6px 4px 9.6px;
	}
	.MuiDialogActions-root {
		padding: 6px 0;
		overflow-y: visible;
		overflow-x: hidden;
	}

  .MuiDialog-paperScrollPaper {
    max-height: 100%;
  }
  .MuiDialog-paper {
    overflow-y: initial;
    margin: auto;
  }
	.baseformbtn {
		i {
			font-size: 1.2rem;
		}
	}
	.component_modal {
		&.entity_modal {
			margin-bottom: 14px;
		}
		.form {
			margin: 6px 0;
		}
	}

	.Intelli_select {
		.select_container {
			.select__control {
				height: 30px;
			}
			.select__single-value {
				font-size: 11.5px;
			}
		}
	}

	.Intelli_divider {
		> span {
			padding: 0 12px;
			font-size: 11.2px;
			font-weight: 500;
			letter-spacing: 0.4px;
			top: 9px;
		}
		&.header {
			padding-top: 0px;
			padding-bottom: 18px;
			> span {
				top: -6px;
			}
		}
	}

	.Intelli_info {
		font-size: 11px;
	}

	p,
	a {
		font-size: 11.2px;
	}

	.intelliFormControl_root {
		.MuiInputBase-root {
			height: 30px;
		}
		input {
			font-size: 11.5px;
		}

		.intelliInputLabel_root {
			&.MuiInputLabel-outlined {
				font-size: 12px;
				.MuiInputLabel-marginDense {
					transform: translate(14px, 10px) scale(1);
				}
			}
		}
	}

	.Intelli_datepicker {
		.MuiOutlinedInput-root {
			height: 30px;
		}
	}

	.formRow {
		padding: 0;
	}
}

.image_modal {
	.MuiDialogContent-root:first-child {
		padding: 0;
	}
	.MuiDialogContent-root {
		height: 550px;
	}
	img {
		height: 550px;
	}
	button {
		position: absolute;
		right: 0;
		border-color: $softGrey;
		border-radius: 3px;
		&:hover {
			cursor: pointer;
			opacity: 1;
		}
		opacity: 0.5;

		i {
			font-size: 22px;
			color: $fontBlack;
		}
	}
}

.baseformbtn {
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	i {
		font-size: 1.2rem;
	}

	.Intelli_FormControlLabel_root {
		margin: 0 !important;
	}
}

.intelliButtonBase_timelog {
	&.colorDisabled {
		background-color: $softGrey !important;
		cursor: pointer;
	}
}

.Intelli_info {
	display: flex;
	font-size: 0.8rem;
	padding: 0 4px;
	color: $fontBlack;

	width: 100%;
	span {
		// text-overflow: ellipsis;
		// white-space: nowrap;
		// overflow: hidden;
		margin-top: auto;
		margin-bottom: auto;
	}
	.name {
		font-weight: 600;
		// padding-bottom: 2px;
		margin-right: 4px;
	}

	.value {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}

.Intelli_divider {
	width: 100%;
	position: relative;
	padding-top: 15px;
	padding-bottom: 14px;
	> span {
		position: absolute;
		top: 10px;
		left: 22px;
		background-color: white;
		padding: 0 12px;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.4px;
	}
	.line {
		width: 100%;
		height: 1px;
		background-color: rgba(0, 0, 0, 0.2);
	}
}

.Intelli_chip.MuiChip-root {
	background-color: $colorGrey;
	border: 1px solid $colorPrimaryLight;
	&.colorPrimary {
		color: white;
		background-color: $colorPrimary;
	}

	&.colorSecondary {
		color: black;
		background-color: $colorSecondaryLight;
	}

	&.colorGrey {
		color: black;
		background-color: $colorGrey;
	}
}

.Intelli_FormControlLabel_root {
	.Intelli_FormControlLabel_label {
		font-size: 0.8rem;
	}
}

.Intelli_switch {
	.MuiSwitch-colorPrimary.Mui-checked {
		color: $colorPrimary;
	}
}

.formRow {
	.Intelli_FormControlLabel_root {
		margin: auto;
	}

	// .Intelli_info {
	//   width: 100%;
	// }
}

.Intelli_loading {
	width: 100%;
	height: 100%;
	z-index: 50000;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	background-color: $colorPrimaryLight;
	opacity: 0.4;
}

.webcam-component {
	width: 100%;
	height: fit-content;

	.buttons {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		margin-bottom: 7px;
	}

	.photo {
		display: flex;
		position: relative;
		.webcam {
			height: auto;
			margin: auto;
		}

		margin-bottom: 13px;

		.silhouette {
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			img {
				width: 273px;
				// width: 90%;
				margin: auto auto 0 auto;
				height: 100%;
				opacity: 0.25;
				content: url("https://staticmyintelli.s3.amazonaws.com/app/person.png");
			}
		}
	}
}

.inputSelecSearch {
	position: absolute;
	left: 4px;
	bottom: -2.7em;
	border: 1px solid;
	width: 98%;
	padding: 2px;
	border-radius: 4px;
	z-index: 5001 !important;
}

.Intelli_select {
	position: relative;
	.select_container {
		.select {
			&__control {
				min-height: 5px;
				height: 32px;

				&--is-focused {
					border-color: $colorPrimary;
					box-shadow: 0 0 0 1px $colorPrimary;
				}
			}
			&__menu {
				z-index: 5000;
				&-list {
					@extend .Intelli_scroll;
				}
			}

			&__option {
				font-size: 12px;
			}

			&__single-value {
				font-size: 12px;
			}

			&__indicator {
				padding: 6px;
			}

			&__placeholder {
				font-size: 12px;
			}
		}

		&--is-multi {
			.select {
				&__menu {
					z-index: 5000;
					margin-top: 7px;
					padding-top: 35px;
					&-list {
						@extend .Intelli_scroll;
					}
				}
				&__control {
					.intelliTruncate_default {
						padding: 0 0 0 7px;
					}
					.intelliChips_root {
						font-size: 12px;
						border-radius: 0.3em;
						height: 17px;
						margin: 0 2px;
					}
				}
			}
		}
	}

	.sel_placeholder_disable {
		font-size: 12px;
		@extend .sel_placeholderB;
	}
	.select__control--is-disabled {
		background-color: white;
		border-color: #cccccc;
	}
}

.Intelli_datepicker {
	width: 100%;
	input {
		font-size: 13px;
		text-transform: uppercase;
	}
	&.MuiFormControl-marginNormal {
		margin: 0;
	}
	.MuiOutlinedInput-adornedEnd {
		padding-right: 0;
	}
	.MuiOutlinedInput-input {
		padding: 9px 15px;
	}

	.MuiOutlinedInput-root {
		height: 32px;
	}

	&.month {
		font-size: 0.7rem;
	}

	&.date {
		.MuiInputAdornment-positionEnd {
			margin-left: 0;
		}
		.MuiOutlinedInput-input {
			padding: 9px 10px;
		}
	}

	&.time {
		.MuiInputAdornment-root {
			display: none;
		}
		.MuiInputLabel-outlined {
			transform: translate(14px, 10px) scale(1);
			font-size: 13px;
			&.MuiInputLabel-shrink {
				transform: translate(14px, -6px) scale(0.85);
			}
		}

		.MuiOutlinedInput-notchedOutline {
			legend {
				font-size: 0.7em;
			}
		}
	}

	.MuiFormLabel-root {
		&.Mui-focused {
			color: $colorPrimary;
		}
	}
	&.Mui-focused {
		color: $colorPrimary;
		border-color: $colorPrimary !important;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: $colorPrimary !important;
	}

	.MuiInputLabel-outlined {
		transform: translate(14px, 14px) scale(0.85);
	}
}

.MuiPickersBasePicker-container {
	.MuiTypography-subtitle1 {
		font-size: 0.9rem;
	}
	.MuiPickersToolbar-toolbar {
		height: fit-content;
		background-color: $blueIntelli;
		.MuiTypography-h4 {
			font-size: 1rem;
		}
	}
	.MuiPickersBasePicker-pickerView {
		width: fit-content;
		min-height: 170px;
	}
	.MuiPickersMonthSelection-container {
		width: 100%;

		.MuiPickersMonth-root {
			height: 45px;
		}
		.MuiTypography-h5 {
			font-size: 1.4rem;
		}
	}
	.MuiPickersYearSelection-container {
		height: 220px;
	}
}

.IntelliSelect {
	position: relative;
	margin: 4px 0;
	padding: 0 0.3rem !important;
}

.select__menu {
	.select__menu-list {
		@extend .Intelli_scroll;
	}
}

.intelliAsyncSelect {
	&-container {
		margin: 0.3rem !important;
		font-size: 0.3rem !important;
	}

	&__control {
		overflow: hidden;
		min-height: 0.8rem !important;
	}

	&__value-container {
		padding: 0.2rem 0.4rem !important;
	}

	&__multi-value {
		&__label {
			font-size: 0.75rem !important;
		}
	}

	&__dropdown-indicator {
		padding: 0.2rem 0.4rem !important;
	}
	.select__value-container--is-multi {
		@extend .Intelli_scroll;
		height: 28px;
	}

	.select__multi-value {
		position: relative;
		padding-right: 20px;
		height: 0.9rem;
		.select__multi-value__remove {
			position: absolute;
			right: 0px;
			top: 0;
			bottom: 0;
			background: #e6e6e6;
			&:hover {
				background: #ffbdad;
			}
		}
		&__label {
			padding: 1px 3px 3px 6px;
		}
	}
}

.sel_placeholderA {
	position: absolute;
	color: #0000007f;
	font-size: 0.75rem;
	font-weight: 400;
	padding: 0px 4px 0px 4px;
	margin-top: -0.5em;
	margin-left: 0.1rem;
	left: 8px;
	top: -1px;
	background-color: white;
	z-index: 1 !important;
	transition: all 500ms;
	top: -1px;
	height: 11px !important;
}

.sel_placeholderB {
	position: absolute;
	color: #0000007f !important;
	font-size: 0.75rem;
	font-weight: 400;
	padding: 0px 4px 0px 4px;
	margin-top: -0.5em;
	margin-left: 0.1rem;
	left: 8px;
	background-color: white;
	font-weight: 400;
	z-index: 1 !important;
	transition: all 500ms;
}

.intelliFormControl_root {
	input {
		font-size: 12px;
	}
	&.Mui-focused {
		color: $colorPrimary;
		border-color: $colorPrimary !important;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: $colorPrimary !important;
	}

	.MuiInputBase-root {
		height: 32px;
	}
	.MuiInputLabel-outlined.MuiInputLabel-marginDense {
		transform: translate(14px, 10px) scale(1);
		font-size: 13px;
		&.MuiInputLabel-shrink {
			transform: translate(14px, -5px) scale(0.85);
		}
	}
}

// .intelliInputLabel {
// 	&_root {
// 		&.MuiInputLabel-marginDense {
// 			transform: translate(14px, 10px) scale(1);
// 		}
// 	}
// }

.tooltipCard {
	&_tooltip {
		background: #000000c0 !important;
		color: white !important;
		font-size: 0.9em !important;
		margin: 0 !important;
		border: none !important;
		// text-transform: uppercase;

		&_verified {
			background-color: $colorPrimary !important;
			font-size: 0.9em !important;
		}

		&_notVerified {
			background-color: $colorPrimary !important;
			font-size: 0.9em !important;
		}

		&_default {
			background-color: $colorPrimary !important;
			font-size: 0.9em !important;
			// color: black !important;
		}
	}

	&_arrow {
		&_verified {
			color: $colorPrimary !important;
			font-size: 0.9em !important;
		}

		&_notVerified {
			color: $colorPrimary !important;
			font-size: 0.9em !important;
		}

		&_default {
			color: $colorPrimary !important;
			font-size: 0.9em !important;
			// color: black !important;
		}
	}

	&_html {
		background: #000000c0 !important;
		color: white !important;
		font-size: 0.8em !important;
		margin: 0 !important;
		border: none !important;
		text-transform: uppercase;
		max-width: 130px !important;
	}

	&_htmlA {
		background: #000000c0 !important;
		color: white !important;
		font-size: 0.8em !important;
		margin: 0 !important;
		border: none !important;
		text-transform: uppercase;
		max-width: 650px !important;
	}

	&_htmlB {
		background: #000000c0 !important;
		color: white !important;
		font-size: 0.8em !important;
		margin: 0 !important;
		padding: 10px 20px !important;
		border: none !important;
		text-transform: uppercase;
		max-width: 250px !important;
	}
}

.Intelli_toast {
	.Toastify__toast {
		padding: 8px;
		&-body {
			padding: 0px;
		}

		&--info {
			background: #58abc3;
		}

		&--success {
			background: #69bf79;
		}

		&--error {
			background: #db6a64;
		}
		&--warning {
			background: #f7a844;
		}
	}
	.toast_msg {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		.icon {
			flex: 0 1 45px;
			display: flex;
			// border: 1px solid white;
			// border-radius: 3px;
			i {
				margin: auto;
				font-size: 25px;
			}
		}
		.data {
			flex: 1 1 10%;
			display: flex;
			flex-direction: column;
			.title {
				flex: 0 1 50%;
				font-weight: 600;
			}

			.text {
				flex: 0 1 50%;
			}
		}
	}
}

// .Intelli_tooltip {
//   .tooltip {
//     color: red;
//     font-size: 15px;
//   }
// }

.Intelli_icon {
	display: flex;
	min-width: 32px;
	.icon {
		margin: auto;
		i {
			font-size: 15px;
		}
	}
}

.support_menu {
	.MuiListItem-root {
		padding: 4px 16px;
		span {
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0.5px;
		}
	}

	.footer_menu {
		border-top: 1px solid $softGrey;
		.section {
			font-size: 13px;
			display: flex;
			padding: 3px 16px;
			span {
				// margin: auto auto auto 0;
				font-weight: 400;
				margin-right: 5px;
				font-family: "Roboto", "Helvetica", "Arial", sans-serif;
				letter-spacing: 0.5px;
			}
		}
	}
}

.image {
	display: flex;
	height: 100%;
	max-width: 500px;
	width: 100%;
	img {
		margin: auto;
		height: 100%;
	}

	&:hover {
		img {
			transition: 0.2s;
			border: 1px solid rgba(46, 194, 255, 0.52);
		}
		cursor: pointer;
	}

	&_circle {
		img {
			border-radius: 50%;
			height: 19px !important;
			width: 19px;
		}
		&_timelogsComponet {
			img {
				border-radius: 50%;
				height: 45px;
				width: 45px;
			}
		}
	}
}
