.draggable {
  display: flex;
  justify-content: space-around;
  height: 100%;
  padding-right: 5px;
}

$height-row: 3em;
$bg-titles-y: #3b6aa6;
$bg-person-label-even: #ebebeb;
$bg-person-label-odd: #ebebeb;
// $opacity-item-hover: 0.7;
// $transition-opacity: 0.2s;
$whiteFontColor: #ececec;
div.container-grid-targetDrops {
  overflow: auto;
  height: 100%;
  flex-wrap: nowrap;
  flex: 1 1 83%;
  max-width: 100%;
}

.row-schedule-titles {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 0.1em 0;
  position: sticky;
  top: 0px;
  z-index: 100;
  .title-blank {
    flex: 0 1 8%;
    user-select: none;
    height: 2.5rem;
    padding: 0%;
    background-color: $bg-titles-y;
    padding: 0;
    margin: 0 1px 0 0;
    // color: $whiteFontColor;
    border-radius: 0.2em;
    // border-bottom-left-radius: 0.5em;
    // border-top-left-radius: 0.5em;
    display: flex;

    h6.MuiTypography-subtitle1 {
      font-size: 0.8rem;
      color: $whiteFontColor;
    }
    .MuiCardContent-root {
      flex: 1;
      padding: 0em 0.1em;
      margin: auto;
      text-align: center;
    }
  }
  .title {
    padding: 0;
    margin: 0;
    background-color: $bg-titles-y;
    user-select: none;
    text-align: center;
    height: 2.5rem;
    border-radius: 0.1em;
    display: flex;
    margin: 0 1px;
    h6 {
      font-size: 0.68rem;
      color: $whiteFontColor;
      height: 1rem;
    }
    .MuiCardContent-root {
      flex: 1;
      padding: 0em 0.1em;
      margin: auto;
    }
  }
}

.row-schedule-person {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0.1em;
  margin-bottom: 15px;

  .person-header-row {
    flex: 0 1 8%;
    min-height: $height-row;
    border-radius: 0.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &.even {
      background-color: $bg-person-label-even;
    }
    &.odd {
      background-color: $bg-person-label-odd;
    }
    user-select: none;
    color: rgba(0, 0, 0, 0.65);
    .MuiCardContent-root {
      min-height: 3.1rem;
      padding: 5px 5px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      span {
        flex: 0 1 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.68rem;
        font-weight: 400;
        // margin: 0 0.2rem 0 0;
        text-align: center;
      }
    }
    h6 {
      color: white;
    }
  }

  .month {
    .item-dropped {
      padding: 0.1em;

      height: 1.5em;
      button {
        margin: 0 auto;
      }
    }
  }

  .week {
    .item-dropped {
      padding: 0.1em 0.1em 0.1em 0.4em;
      height: 1.5em;
      display: flex;
      button {
        margin: 0 0 0 auto;
      }

      .name {
        flex: 0 1 10%;
      }

      .time {
        flex: 0 1 80%;
        text-align: center;
      }
    }
  }

  .target-drop {
    padding: 0 1px;
    margin: 0 1;
    min-height: $height-row;
    user-select: none;
    background-color: #0000000a;
    border-radius: 0.2em;
    margin: 0 0.1em;

    //Centrar itemsDropped
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:hover {
      background-color: rgba(148, 148, 221, 0.15);
    }

    .MuiCardContent-root {
      padding: 4px 3px;
    }

    .MuiBadge-root {
      width: 100%;
    }

    .item_dropped {
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      border-radius: 0.2em;
      height: 1.4rem;
      margin: 1px 0;
      cursor: pointer;
      font-size: 0.9rem;
      &:hover {
        opacity: $opacity-item-hover;
        transition: $transition-opacity;
      }

      .siglas {
        flex: 0 1 auto;
        border-radius: 0.2em 0 0 0.2em;
        // padding: 0 0.2em;
        width: 24%;
        text-align: center;
        display: flex;
        .name {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin: auto;
        }
      }
      > .time {
        flex: 0 1 auto;
        width: 76%;
        text-align: center;
        border-radius: 0 0.2em 0.2em 0;
        display: flex;
        > .time {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin: auto;
        }
      }
      > .info-button {
        flex: 0 1 auto;
        border-radius: 0 0.2em 0.2em 0;
        width: 6%;
        text-align: center;
        button {
          font-size: 0.9rem;
        }
      }
    }
  }
}

.source-items {
  width: 100%;
  height: 50%;
  .MuiCardHeader-root {
    padding: 7px;
  }
  .MuiCardContent-root {
    padding: 0;
    height: 90%;
    &:last-child {
      padding: 0;
    }
  }
  .container-items {
    height: 89%;
  }
  .IntelliSelect {
    margin-top: 5px;
  }

  .container-item {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    .item {
      height: 2em;
      padding: 0;
      margin: 2px 5px;
      text-align: center;
      cursor: move;
      color: white;
      flex: 0 1 100%;
      display: flex;
      flex-wrap: nowrap;
      border-radius: 0.2rem;
      width: 13rem;
      &:hover {
        opacity: $opacity-item-hover;
        transition: $transition-opacity;
      }

      p {
        font-size: 0.8rem;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 0 1 100%;
        margin: 0;
      }
      .MuiIconButton-sizeSmall {
        flex: 0 1 5%;
        font-size: 1rem;
        padding: 5px;
      }
      .siglas {
        // width: 20%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex: 0 1 20%;
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        span {
          // font-size: 0.88rem;
          width: 90%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .time,
      .name {
        // width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex: 0 1 80%;
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .delete-button {
        flex: 0 1 10%;
      }
    }
    .delete-checked {
      flex: 0 1 13%;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        padding: 0;
        font-size: 1.2rem;
      }
      i {
        color: #f44336;
      }
      &:hover {
        i {
          color: #b41b10;
        }
      }
    }
  }

  .MuiTypography-h5 {
    font-size: 1.2rem;
  }
}

.item-dropped-menu {
  .MuiList-root {
    padding: 0.1em 0;

    .MuiListItem-root {
      padding: 2px;

      button {
        font-size: 0.8em;
      }
    }
  }
}

.item-menu-styled {
  display: flex;
  flex-wrap: nowrap;
  .icon-button {
    flex: 0 1 18px;
    width: 18px;
    display: flex;
    justify-content: center;
  }
  .text-button {
    flex: 1 1 90%;
    width: 90%;
    font-size: 0.8rem;
    font-weight: 400;
    color: #616161;

    margin: 0 3px;
  }
}
