.login-page {
	height: 100%;
	width: 100%;
	display: flex;
	background-color: gray;
	flex-wrap: wrap;

	background-image: url("https://staticmyintelli.s3.amazonaws.com/app/login/fondo-myintelli.jpg");
	background-size: 100% 100%;
	position: relative;

	.login-header {
		flex: 0 1 100%;
		height: 13%;
		display: flex;
		.logoIntellinext,
		.selectLang-container {
			flex: 0 1 50%;
			display: flex;
			justify-content: flex-end;
			padding: 48px 55px;
			.selectLang {
				height: fit-content;
				display: flex;
				.selectLang_icon {
					margin-right: 4px;
				}
				.selectLang_select {
				}
			}
		}

		.logoIntellinext {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 31px 29px;
			position: relative;
		}
	}

	.login-main {
		flex: 0 1 100%;
		height: 60%;
		display: flex;

		&__logo,
		&__form {
			flex: 0 1 50%;
		}

		&.recovery {
			flex-direction: column;
			justify-content: center;
			span {
				margin: 0 auto;
				font-size: 26px;
			}

			.expired_session_recovery {
				border: 2px solid #707070;
				border-radius: 15px;
				padding: 1rem;
				width: 300px;
				margin: 0 auto;
				display: flex;
				flex-direction: column;
				align-items: center;
				> h5 {
					flex: 0 1;
				}
				.intelliButtonBase {
					margin: 0 auto;
				}
			}
		}

		&__form {
			.login-container {
				&__form {
					max-width: 400px;
					border: 2px solid #707070;
					border-radius: 15px;
					padding: 1rem;
					.user-field,
					.password-field {
						margin: 7px 0;
						display: flex;
						.icon {
							margin: 0 5px 0 0;
							display: flex;
							font-size: 1.3rem;
							color: $colorPrimary;
							align-items: flex-end;
							justify-content: center;
							i {
								margin: 0 0 8px 0;
							}
						}
					}

					.MuiInputLabel-shrink {
						transform: translate(0, 1.5px) scale(1);
					}
				}

				&__submit {
					margin: 10px 0;
					&.recover {
						justify-content: flex-end;
					}
				}
			}
			a {
				font-size: 0.9rem;
				font-weight: 600;
				color: #3b6aa6;
			}
			&.recovery-form {
				margin: 0 auto;
			}
		}

		&__logo {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			top: 6%;
			.main-circle {
				position: relative;
				width: 79vh;
				img {
					height: 100%;
					width: 100%;
				}
				.icon-1,
				.icon-2,
				.icon-3,
				.icon-4,
				.icon-5,
				.icon-6 {
					position: absolute;
					width: 23%;
				}

				.icon-1 {
					top: 10%;
					left: -2%;
				}
				.icon-2 {
					top: -5%;
					left: 17.5%;
				}
				.icon-3 {
					top: -9%;
					left: 42%;
				}
				.icon-4 {
					bottom: -11%;
					left: 42%;
				}
				.icon-5 {
					bottom: -4%;
					left: 65%;
				}
				.icon-6 {
					bottom: 13%;
					left: 83%;
				}
			}
		}

		&__form {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.login-footer {
		flex: 0 1 100%;
		height: 22%;
		display: flex;
		align-items: flex-end;
		div {
			text-align: center;
			flex: 0 1 100%;
		}

		.login__rights {
			font-size: 12px;
		}
	}

	.divider-line {
		width: 5px;
		position: absolute;
		left: 50%;
		height: 75%;
		top: 15%;
		background: radial-gradient(circle, #0070aa, transparent);
	}
}

$blueKeral: #27a7cd;
$fontGrey: #444444;
$darkBlueKeral: #0a3a7a;

.keral-login-page {
	height: 100%;
	width: 100%;
	display: flex;
	background-color: gray;
	flex-wrap: wrap;
	color: $fontGrey;
	background-image: url("https://staticmyintelli.s3.amazonaws.com/keralty/aplicativo.jpg");
	background-size: 100% 100%;
	position: relative;
	// opacity: 0.6;
	justify-content: center;
	align-items: center;
	font-size: 1.3rem;

	.keral-login-form {
		width: 600px;
		border-radius: 30px;
		height: 70%;
		background: rgb(190, 190, 190);
		background: linear-gradient(
			328deg,
			rgba(190, 190, 190, 0.7399334733893557) 79%,
			rgba(97, 97, 97, 1) 100%
		);

		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;

		&__logo {
			flex: 0 1 16%;
			height: 16%;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				height: 100%;
				opacity: 1;
			}
		}
		&__text {
			flex: 0 1 10%;
			padding: 0 42px;
			font-weight: 400;
			text-align: justify;
		}
		&__fields {
			flex: 0 1 135px;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: column;
			.field {
				flex: 0 1 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				.input-keral {
				}
			}
		}
		&__button {
			flex: 0 1 90px;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-evenly;
			.text {
				flex: 0 1 auto;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.button {
				flex: 0 1 auto;
				display: flex;
				justify-content: center;
				align-items: center;

				.MuiButtonBase-root {
					background-color: $darkBlueKeral;
					color: #cccccc;
					text-transform: capitalize;
					font-size: inherit;
					border-radius: 12px;
					padding: 7px 64px;
				}
			}
		}
		&__rights {
			flex: 0 1 10%;
			display: flex;
			flex-wrap: nowrap;
			justify-self: flex-end;
			margin-top: auto;
			justify-content: space-evenly;
			.rigths {
				flex: 0 1;
				display: flex;
				justify-content: center;
				align-items: center;
				white-space: nowrap;
			}
			.intelli-logo {
				flex: 0 1;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: 220px;
				}
			}
		}
	}

	.Keraltybg_logo {
		position: absolute;
		bottom: 0;
		right: 0;

		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 300px;
		}
	}
}

.recoverModal {
	.baseformbtn {
		border-top: none;
	}
}

.input-keral {
	background-color: white;
	border-radius: 15px;
	height: 43px;
	padding: 0 15px;
	display: flex;
	font-size: 1.3rem;
	width: 70%;
	&:hover {
		outline: 1px solid $blueKeral;
	}
	&:focus-within {
		outline: 2px solid $blueKeral;
		// transition: 0.3s;
	}
	.icon {
		flex: 0 1 31px;
		width: 31px;
		display: flex;
		justify-items: center;
		align-items: center;
		margin: 0 10px 0 0;
		i {
			color: #b9b7b5;
			margin: auto;
		}
		font-size: inherit;
	}
	.input {
		flex: 1 1 10%;
		display: flex;
		justify-content: start;
		align-items: center;
		width: 100%;
		input {
			font-size: 1.04rem;
			// font-size: inherit;
			width: 100%;
			border: none;
			&:hover,
			&:focus,
			&:focus-visible {
				outline: none !important;
			}
			&::placeholder {
				color: #b4b4b4;
			}

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			&[type="number"] {
				-moz-appearance: textfield;
			}
		}
	}
	.select {
		flex: 1 1 10%;
		display: flex;
		justify-content: start;
		align-items: center;
		select {
			border: none;
			&:hover,
			&:focus,
			&:focus-visible {
				outline: none !important;
			}
			font-size: inherit;
			&::placeholder {
				color: #b4b4b4;
			}
			background-color: white;

			option {
				padding: 0 5px;
			}
		}
	}

	.MuiInputBase-root {
		width: 100%;

		.MuiSelect-root {
		}
		&.MuiInput-underline {
			&::before,
			&::after {
				display: none;
			}
		}
	}
}

.dialog-help {
	position: relative;
	i {
		position: absolute;
		font-size: 1.2rem;
		right: 0;
		margin: 7px 9px;
		cursor: pointer;
		color: #444444;
		&:hover{
			transition: 0.2s;
			color: black;
		}
	}
}
