.fabIconSmall {
    height: 30px !important;
    width: 30px !important;
    min-width: unset !important;
    min-height: unset !important;
    color: white !important;
  
    &_active {
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.5) !important;
    }
  
    &_disabled {
      //box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.5) !important;
      background-color: #cbcbcc !important;
    }
    &_activeBlackList {
      box-shadow: inset 1px 2px 2px rgba(200, 200, 200, 1) !important;
    }
    &:hover {
      //background: $auxiliarPrimary !important;
      filter: contrast(110%) brightness(80%);
      -webkit-filter: contrast(110%) brightness(80%);
      //box-shadow: 0 0 5px 1px $colorPrimaryTA !important;
    }
}