.timelogs_component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  > .title {
    flex: 0 1 50px;
    display: flex;
    span {
      margin: auto;
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
  .buttons {
    flex: 0 1 120px;
    display: flex;
    justify-content: space-evenly;
    padding: 0 15px;
    align-items: center;
    &__in,
    &__out {
      height: 100%;
      flex: 0 1 32%;
      .intelliButtonBase_timelog {
        border-radius: 8px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        -webkit-box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.25);

        > div {
          z-index: 5;
        }
        .icon {
          flex: 0 1;
          height: fit-content;
          display: flex;
          i {
            margin: auto;
            font-size: 80px;
          }
        }
        .text {
          flex: 1 1;
          height: 5px;
          display: flex;
          span {
            margin: auto;
            font-size: 25px;
          }
        }
      }
    }

    &__in {
      .intelliButtonBase_timelog {
        background-color: $entryColor;
        color: white;
        border: 2px solid $colorSuccessDark;
        @extend .hoverSuccess;
      }
    }

    &__out {
      .intelliButtonBase_timelog {
        background-color: $exitColor;
        color: white;
        border: 2px solid $colorAlertDark;
        @extend .hoverAlert;
      }
    }

    .timelog_dateTime {
      height: 100%;
      flex: 1 1;
      width: 10px;
      display: flex;
      flex-direction: column;
      text-align: center;
      .date {
        margin: auto auto 0 auto;
        font-size: 14px;
      }
      .time {
        // font-family: digitalNormal;
        font-family: digitalItalic;
        // font-family: digitalBold;
        // font-family: digitalItalicBold;
        margin: 5px auto auto auto;
        font-size: 31px;
      }
    }
  }

  > .timelogs-cards {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    border: 2px solid $colorSecondary;
    border-radius: 10px;

    padding: 4px;
    margin: 15px;

    &.Intelli_scroll::-webkit-scrollbar {
      width: 5px;
    }

    .timelog-card {
      flex: 0 1 62px;
      width: 100%;
      height: 62px;
      min-height: 62px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      border-radius: 8px;
      overflow: hidden;

      margin: 0 0 4px 0;

      .head {
        flex: 0 1 70px;
        display: flex;
        transition: 0.2s;
        cursor: pointer;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        flex-direction: row;
        border-left: 2px solid $softGrey;
        border-top: 2px solid $softGrey;
        border-bottom: 2px solid $softGrey;

        > .image {
          flex: 0 1 100%;
          display: flex;
        }
      }
      .content {
        flex: 1 1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: white;
        border-right: 2px solid $softGrey;
        border-top: 2px solid $softGrey;
        border-bottom: 2px solid $softGrey;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 0 8px 0 0;
        .Intelli_info {
          margin: auto 0;
        }
        .container_icon {
          .icon {
            flex: 1 1 10px;
            display: flex;
            span {
              margin: auto;
              i {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.modal-timelogs-details {
  width: 100%;

  .data {
    width: 100%;
    height: 98px;
    display: flex;
    margin-bottom: 10px;

    flex-direction: row;
    // border: 1px solid black;

    .image {
      flex: 0 1 12px;
      max-width: 500px;
      border: 1px solid $softGrey;
      img {
        height: 100%;
        cursor: pointer;
      }

      transition: 0.2s;
    }
    .meta-data {
      padding: 2px 6px;
      flex: 1 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: 100%;
    }
  }
  .map {
    width: 100%;
    height: 300px;
    display: flex;
    border: 1px solid $softGrey;
  }
}
