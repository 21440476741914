$borderPrueba: 1px solid #0003;
$backgroundPerson: #2d94a1;

$primary1: #0288d1;
$primary2: #64b2cba6;
$primary3: #58a5f0;
$accent1: #ff4081;

.timeline {
  user-select: none;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 15px;
  // cursor: crosshair;

  .person {
    flex: 0 1;
    height: 3.7rem;

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 9fr;
    margin: 1.5rem 0;
    .name {
      grid-column: 1 / 2;
      display: flex;
      flex-direction: row;
      // border-top-left-radius: 0.4rem;
      // border-bottom-left-radius: 0.4rem;
      color: rgba(0, 0, 0, 0.65);
      transition: 0.2s;

      .container-name {
        flex: 0 1 100%;
        background-color: #cfd8dc;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.4rem;
        p {
          text-align: center;
          font-size: 0.7rem;
          margin: 0;
          font-weight: 500;
          letter-spacing: 0.003rem;
          white-space: pre-wrap;
        }
        &:hover {
          background-color: #e4e4e4;
          transition: 0.2s;
        }
      }
      .bg-empty {
        background-color: $primary1;
      }
      .workshifts-columns {
        flex: 0 1 30%;
        width: 30%;
        display: flex;
        flex-wrap: wrap;
        position: relative;

        .ellipsis {
          position: absolute;
          right: 2px;
          bottom: 2px;
          color: white;
        }
        .workshift {
          flex: 0 1 100%;
          display: flex;
          width: 100%;
          padding: 0 0.2rem;

          .cell,
          .cell-principal {
            flex: 0 1 100%;
            width: 60%;
            display: flex;
            font-size: 0.7rem;
            align-items: center;
            justify-content: center;
            font-size: 0.6rem;
            div {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          .cell-principal {
            flex: 0 1 40%;
            width: 40%;

            height: 100%;
          }
        }
      }
      p {
        font-size: 0.6rem;
        // flex: 0 1;
      }
    }

    .grid-timeline {
      height: 100%;
      width: 100%;
    }

    .texture-approval {
      background: url("https://staticmyintelli.s3.amazonaws.com/texture-1.png")
        repeat;
      &-permission {
        background: url("https://staticmyintelli.s3.amazonaws.com/texture-2.png")
            repeat,
          linear-gradient(
            180deg,
            rgba(255, 245, 157, 1) 0%,
            rgba(203, 194, 109, 1) 100%
          );
      }
      &-HOUR {
        background: url("https://staticmyintelli.s3.amazonaws.com/texture-1.png")
            repeat,
          linear-gradient(
            180deg,
            rgba(165, 214, 167, 1) 0%,
            rgba(117, 164, 120, 1) 100%
          );
      }
      &-EXTRA {
        background: url("https://staticmyintelli.s3.amazonaws.com/texture-1.png")
            repeat,
          linear-gradient(
            180deg,
            rgba(255, 204, 128, 1) 0%,
            rgba(202, 155, 82, 1) 100%
          );
      }

      &-COMPENSATORY {
        background: url("https://staticmyintelli.s3.amazonaws.com/texture-1.png")
            repeat,
          linear-gradient(
            180deg,
            rgba(206, 147, 216, 1) 0%,
            rgba(156, 100, 166, 1) 100%
          );
      }
      &-PERMISSION {
        background: url("https://staticmyintelli.s3.amazonaws.com/texture-1.png")
            repeat,
          linear-gradient(
            180deg,
            rgba(255, 245, 157, 1) 0%,
            rgba(203, 194, 109, 1) 100%
          );
      }
      &-ABSENCE,
      &-A,
      &-DELAY {
        background: url("https://staticmyintelli.s3.amazonaws.com/texture-1.png")
            repeat,
          linear-gradient(
            180deg,
            rgba(245, 245, 245, 1) 0%,
            rgba(194, 194, 194, 1) 100%
          );
      }
    }

    .texture-other-day {
      background: url("https://staticmyintelli.s3.amazonaws.com/texture-other_day.png")
        repeat;
    }

    .bar {
      padding: 0.2rem 0rem 0.2rem 0;
      grid-column: 2 / 3;
      border: $borderPrueba;
      position: relative;
      display: flex;
      align-items: center;

      .bars-regions-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
        align-items: center;
      }

      .superior-bar-container {
        flex: 0 1 100%;
        position: relative;
        height: 14%;
      }

      .rnd-container {
        flex: 0 1 100%;
        margin: 1.5px 0;
        position: relative;
        height: 48%;
        width: 100%;
        display: flex;
        align-items: center;
      }

      .inferior-bar-container {
        flex: 0 1 100%;
        position: relative;
        height: 14%;
        .bar_marking {
          height: 100%;
        }
      }
    }
    .barRnd {
      overflow: hidden;
      border-radius: 0.3rem;
      .bar-intersected {
        height: 27%;
        background-color: black;
      }
    }

    .droppeable-bar {
      position: absolute;
      border-radius: 0.3rem;
      cursor: pointer;
      transition: 0.2s;
    }

    .flag_marking {
      height: 100%;
      width: fit-content;
      position: absolute;
      top: 0%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      i {
        margin-bottom: 0.2rem;
        position: absolute;
        top: -22px;
      }
      div.tiny-bar {
        flex: 0 1 100%;
        height: 100%;
        width: 2px;
        background-color: #37474f;
        z-index: 10;
      }
    }

    .bar_marking {
      height: 14%;
      position: absolute;
      background-color: #bfbfbf;
      z-index: 5;
    }

    .grid-step {
      height: 100%;
      width: fit-content;
      position: absolute;
      top: 0%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      span {
        margin-bottom: 0.2rem;
        position: absolute;
        top: 102%;
        font-size: 0.7rem;
        color: rgba(0, 0, 0, 0.4);
        white-space: nowrap;
      }
      .dashed-bar,
      .solid-bar {
        flex: 0 1 100%;
        height: 100%;
        width: 1px;
        z-index: 10;
        border-left: 1px dashed rgba(0, 0, 0, 0.4);
      }

      .solid-bar {
        border-left: 1px solid rgba(0, 0, 0, 0.4);
      }
      .new-day {
        color: black;
      }
    }

    .gridBar {
      height: 100%;
      width: 100%;
    }
  }

  > .MuiAccordion-root:nth-of-type(2) {
    margin-top: 0;
  }

  .MuiAccordion-root {
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    margin: 0.1rem 0;
    width: 99.5%;

    $meta-data: #1d1d1d;

    > .Mui-expanded {
      background-color: white;
      border-bottom: 1px solid #d0d0d0;

      min-height: 33px;

      &:last-child {
        margin: 0 0 0.3rem 0;
      }
    }

    .MuiAccordionSummary-content {
      margin: auto 0;
    }

    .MuiTypography-body1 {
      font-size: 0.9rem;
      font-weight: 500;
      color: $meta-data;
    }

    .name-header {
      display: flex;
      justify-content: start;
      align-items: center;
      text-align: center;
      padding: 0 1rem;
      &_name {
        color: $meta-data;
        font-size: 0.86rem;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .meta-data {
      flex: 0 1 20%;
      color: #6b6b6b;
      // margin: auto 0.3rem 0 0.3rem;
      // font-size: 0.65rem;

      .MuiTypography-caption {
        font-weight: 600;
        color: $meta-data;
      }
      .MuiTypography-body1 {
        font-weight: 400;
        color: #6b6b6b;
      }
    }

    .MuiAccordionDetails-root {
      background-color: white;
      padding: 10px 0 0.5rem 0.5rem;
      user-select: none;
      display: flex;
      height: 100%;
      width: 100%;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: stretch;
    }
  }

  .range-date {
    padding: 10px 7px;
    border-top: 1px solid #d4d4d4;
    &_date {
      margin: 0 3px;
      color: #3a3a3a;
      font-weight: 300;
    }
    &_meta {
      color: #151515;
    }
  }
}

.tooltip-timelog {
  // max-width: 500px !important;
  white-space: pre-wrap !important;
}
