.itemTableBadge {
  padding: 1px 6px;
  text-align: center;
  // border-radius: 6px;
  border-radius: 10px;
  margin: 2px;
  color: white;
  // border: 1px solid #000 !important
  &_pointer {
    cursor: pointer;
  }
}
.itemTableBadgeActive {
  background-color: $auxiliarSecondary;
}
.itemTableBadgeInactive {
  background-color: $auxiliarBlackList_t25;
}

.itemEventAlertTimelog {
  background-color: $auxiliarSecondary;
}

.itemTableBadgeBlackList {
  display: flex;
  justify-content: center;
  background-color: $auxiliarDelete;
  // font-weight: 600;
  padding: 0 10px;
  width: auto;
}

.itemTableBadgeBiometricRequired {
  display: flex;
  justify-content: center;
  background-color: $auxiliarFavorites;
  // font-weight: 600;
  padding: 0 10px;
  width: auto;
}
.itemTableBadgeOpened {
  display: flex;
  justify-content: center;
  background-color: $colorSecondaryTA !important;
  // font-weight: 600;
  padding: 0 10px;
  width: auto;
}
.itemTableBadgeClosed {
  display: flex;
  justify-content: center;
  background-color: $auxiliarBlackList_t50 !important;
  // font-weight: 600;
  padding: 0 10px;
  width: auto;
}
.itemTableBadgeLeave {
  display: flex;
  justify-content: center;
  //background-color: $color03;
  //background-color: $colorIntelliLightsTA;
  background-color: #F0B823;
  // color: black;
  // font-weight: 600;
  padding: 0 10px;
  width: auto;
}
.itemTableBadgeApproved {
  display: flex;
  justify-content: center;
  //background-color: $color03;
  //background-color: $colorIntelliLightsTA;
  background-color: #0070aa;
  // color: black;
  // font-weight: 600;
  padding: 0 10px;
  width: auto;
}
.itemTableBadgeEntered {
  display: flex;
  justify-content: center;
  background-color: $colorIntelliAccess !important;
  // background-color: $auxiliarGraphics;
  // color: black;
  // font-weight: 600;
  padding: 0 10px;
  width: auto;
}
.itemTableBadgeCanceled {
  display: flex;
  justify-content: center;
  background-color: $auxiliarDelete !important;
  // background-color: $auxiliarGraphics;
  // color: black;
  // font-weight: 600;
  padding: 0 10px;
  width: auto;
}

.itemTableBadgeBatteryOK {
  background-color: $color24;
}

.itemTableBadgeBatteryFLAT {
  background-color: $color09;
}

.itemTableBadgeBatteryLOW {
  background-color: $color26;
}

.itemTableBadgeActiveOnline {
  background-color: $color24;
}

// Para dìas de la semana
.statusDay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1px 6px;
  text-align: center;
  width: 25px;
  border-radius: 10px;
  margin: 1px;
  color: white;
  float: left;
}
.statusDayActive {
  background-color: $auxiliarSecondary;
}
.statusDayInactive {
  background-color: $auxiliarBlackList_t25;
}
.statusDayActiveOnline {
  background-color: $color24;
}

.itemTableBadgeUsed {
  display: flex;
  justify-content: center;
  background-color: $colorGrey !important;
  padding: 0 10px;
  width: auto;
}

.itemTableBadgeNotUsed {
  display: flex;
  justify-content: center;
  background-color: $colorEntry !important;
  padding: 0 10px;
  width: auto;
}

.itemTableBadgePermissionApproved {
  display: flex;
  justify-content: center;
  background-color: $color22 !important;
  padding: 0 10px;
  width: auto;
}
.itemTableBadgePermissionCanceled {
  display: flex;
  justify-content: center;
  background-color: $auxiliarDelete !important;
  padding: 0 10px;
  width: auto;
}
.itemTableBadgePermissionVerifed{
  display: flex;
  justify-content: center;
  background-color: $color17 !important;
  padding: 0 10px;
  width: auto;
}
.itemTableBadgePermissionOnRequest{
  display: flex;
  justify-content: center;
  background-color: $color05 !important;
  padding: 0 10px;
  width: auto;
}

.itemTableBadgePermissionPending{
  display: flex;
  justify-content: center;
  background-color: $color25 !important;
  padding: 0 10px;
  width: auto;
}

