* {
	box-sizing: border-box;
	// a,
	// p,
	// span {
	//   font-family: Roboto;
	// }
}

html {
	font-size: 15px;
}

#root {
	height: 100vh;
	width: 100%;
}

.Intelli_textfield {
	width: 100%;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.numberLogin {
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}
}

a {
	color: #1890ff;
	text-decoration: none;
	&:hover {
		color: #40a9ff;
	}
}

body {
	// font-family: Roboto !important;
	font-size: 15px;
}

@font-face {
	font-family: "digitalNormal";
	src: local("digitalNormal"),
		url("../../assets/fonts/digital/DS-DIGI.TTF") format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "digitalItalic";
	src: local("digitalItalic"),
		url("../../assets/fonts/digital/DS-DIGII.TTF") format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "digitalBold";
	src: local("digitalBold"),
		url("../../assets/fonts/digital/DS-DIGIB.TTF") format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "digitalItalicBold";
	src: local("digitalItalicBold"),
		url("../../assets/fonts/digital/DS-DIGIT.TTF") format("truetype");
	font-weight: normal;
}

.dashboard_page {
	height: 100%;
	width: 100%;
	display: grid;

	grid-template-columns: 58px auto;
	grid-template-rows: 50px auto;

	.intelli-header {
		grid-column: 1/3;
		grid-row: 1/2;
	}
	.basepage-component {
		grid-column: 1/3;
		grid-row: 2/3;
	}
}

.intelli-header {
	display: flex;
	justify-content: space-between;

	background: linear-gradient(
		90deg,
		$blackIntelli 20%,
		$blueIntelli,
		var(--header-marine-color) 80%
	);
	box-shadow: 0 2px 4px 2px rgba(9, 45, 69, 0.5);

	border-bottom: 1px solid #90caf9;
	color: $fontWhite;

	border-bottom: 1px solid #29b6f6;

	.brand {
		flex: 0 1 auto;
		display: flex;
		a {
			padding: 0 12px;
			margin: auto;
			height: 40px;
			img {
				max-height: 100%;
				&:hover,
				&.__selected {
					-webkit-filter: drop-shadow(0px 0px 0.1px white)
						drop-shadow(0px 0px 0.1px white) drop-shadow(0px 0px 0px white)
						drop-shadow(0px 0px 1px white);
					filter: drop-shadow(0px 0px 0.1px white)
						drop-shadow(0px 0px 0.1px white) drop-shadow(0px 0px 0px white)
						drop-shadow(0px 0px 1px white);
				}
				.header_logo {
					& .__selected {
						-webkit-filter: drop-shadow(0px 0px 0.1px white)
							drop-shadow(0px 0px 0.1px white) drop-shadow(0px 0px 0px white)
							drop-shadow(0px 0px 1px white);
						filter: drop-shadow(0px 0px 0.1px white)
							drop-shadow(0px 0px 0.1px white) drop-shadow(0px 0px 0px white)
							drop-shadow(0px 0px 1px white);
					}
				}
			}
		}
	}

	.datetime-header {
		display: flex;
		align-items: center;
		h6 {
			letter-spacing: 6px;
			font-weight: 400;
		}
		a:-webkit-any-link {
			text-decoration: none;
		}
		i {
			margin: 0 14px 0 7px;
			font-size: 29px;
			color: #89c862;
			&:hover {
				text-shadow: 0 0 10px white;
				cursor: pointer;
			}
		}
	}

	.header-options {
		flex: 0 1 230px;

		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-end;
		padding-right: 10px;

		.user-info {
			flex: 0 1;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			padding: 5px;
			font-size: 10px;
			span {
				&:nth-child(1) {
					font-weight: 500;
					margin: auto 0 0 auto;
					font-size: 11px;
					white-space: nowrap;
				}
				&:nth-child(2) {
					margin: 2px 0 auto auto;
				}
			}
		}

		.user-settings,
		.notifications,
		.exit {
			flex: 0 1 43px;
			width: 50px;
			display: flex;
			i {
				margin: auto;
				font-size: 20px;
				&:hover {
					text-shadow: 0 0 10px white;
					cursor: pointer;
				}
			}
		}

		.user-settings {
			.image {
				padding: 13px;
				img {
					border-radius: 50%;
					border: 1px solid white;
					width: 100%;
					height: 100%;
					&:hover {
						box-shadow: 0 0 10px white;
						cursor: pointer;
					}
				}
			}
		}
	}
}

.intelli-sidebar {
	background-color: $blackIntelli;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	.icon {
		height: 50px;
		width: 100%;
		display: flex;
		a {
			margin: auto;
			font-size: 1.3rem;
			color: white;
		}

		i {
			font-size: 24px;
		}

		&:hover {
			background-color: $hoverGrey;
		}

		&.active {
			background-color: $hoverIcon;
			i {
				color: $colorTealLight;
			}
		}
	}
}

.Intelli_scroll {
	scrollbar-color: #36b8d8 #2e2d38 !important;
	scrollbar-width: thin !important;

	overflow-x: hidden !important;
	overflow-y: auto !important;

	&::-webkit-scrollbar {
		width: 10px;
		background: #4276bb !important;
	}

	&::-webkit-scrollbar-track {
		background: #2e2d38 !important;
	}

	&::-webkit-scrollbar-thumb {
		background: #4276bb !important;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #555 !important;
	}
}

.centerVertical {
	display: flex;
	flex-direction: row;
	align-items: center;
	p {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.intelliTruncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	&_default {
		@extend .intelliTruncate;
		flex: 1;
	}
	&_notification {
		flex: 0.9;
	}
}

.sel_placeholder {
	position: absolute;
	color: $colorPrimary !important;
	font-size: 0.75rem;
	font-weight: 400;
	padding: 0px 4px 0px 4px;
	margin-top: -0.5em;
	margin-left: 0.1rem;
	left: 8px;
	background-color: white;
	z-index: 1 !important;
	transition: all 500ms;
}

sel_placeholderB,
.sel_placeholder {
	height: 12px;
}

.centerVH {
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
}

.centerVH_Column {
	display: flex !important;
	flex-direction: column !important;
	justify-content: center !important;
	align-items: center !important;
}

.logoemp {
	content: url("https://staticmyintelli.s3.amazonaws.com/app/logoemp.png");
}

.header_logo_selected {
	content: url("https://staticmyintelli.s3.amazonaws.com/app/logoheader.png");
}

.header_logo {
	content: url("https://staticmyintelli.s3.amazonaws.com/app/logoheader-selected.png");
}

.tooltipCard {
	&_tooltip {
		background: #000000c0 !important;
		color: white !important;
		font-size: 0.9em !important;
		margin: 0 !important;
		border: none !important;
	}

	&_arrow {
		&_verified {
			color: #000000c0 !important;
			font-size: 0.9em !important;
		}

		&_notVerified {
			color: #000000c0 !important;
			font-size: 0.9em !important;
		}

		&_default {
			color: #000000c0 !important;
			font-size: 0.9em !important;
		}
	}

	&_html {
		background: #000000c0 !important;
		color: white !important;
		font-size: 0.8em !important;
		margin: 0 !important;
		border: none !important;
		text-transform: uppercase;
		max-width: 130px !important;
	}

	&_htmlA {
		background: #000000c0 !important;
		color: white !important;
		font-size: 0.8em !important;
		margin: 0 !important;
		border: none !important;
		text-transform: uppercase;
		max-width: 650px !important;
	}

	&_htmlB {
		background: #000000c0 !important;
		color: white !important;
		font-size: 0.8em !important;
		margin: 0 !important;
		padding: 10px 20px !important;
		border: none !important;
		text-transform: uppercase;
		max-width: 250px !important;
	}
}

.tooltipSidebar {
	background: #000000c0 !important;
	padding: 3px 5px !important;
	margin: 0 0 -5px 5px !important;
	bottom: 0 !important;
	font-size: 1em !important;
	font-weight: normal !important;
	z-index: 5000;
}

.tooltipHeader {
	background: #000000c0 !important;
	padding: 3px 5px !important;
	margin: 5px 0 -5px 0 !important;
	bottom: 0 !important;
	font-size: 1em !important;
	font-weight: normal !important;
	z-index: 5000;
}
