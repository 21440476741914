.component_modal {
	.form {
		.details-schedules {
			.MuiGrid-item {
				flex: 1 1 calc(100% / 9);
			}
		}
		.table {
			display: flex;
			width: 100%;
			height: 100%;
			flex-direction: column;
			flex-wrap: nowrap;
			margin: 7px 0 0 0;

			.Headers {
				flex: 0 1 25px;
				display: flex;
				flex-wrap: nowrap;
				flex-direction: row;
				> div {
					&:nth-of-type(7n) {
						// border: 1px solid white;
						border-bottom: 1px solid white;
						border-top: 1px solid white;
					}
					border-right: 1px solid white;
					border-bottom: 1px solid white;
					border-top: 1px solid white;
					display: flex;
					// background-color: #9c9c9c33;
					background-color: #29b6f6;
					color: white;
					span {
						margin: auto;
					}
				}
				.day,
				.time,
				.type,
				.details {
					flex: 0 1 calc(100% * 1 / 12);
				}
				.time {
					flex: 0 1 calc(100% * 1 / 12);
				}

				.job,
				.location {
					flex: 1 1 10%;
				}
			}
			.row {
				flex: 0 1 25px;
				display: flex;
				flex-wrap: nowrap;
				flex-direction: row;
				cursor: initial;
				> div {
					&:nth-of-type(7n-6) {
						border-right: 1px solid $softGrey;
						border-left: 1px solid $softGrey;
						border-bottom: 1px solid $softGrey;
					}
					border-right: 1px solid $softGrey;
					border-bottom: 1px solid $softGrey;
					display: flex;

					span {
						margin: auto;
						font-size: 12px;
						color: $fontBlack;
					}
				}

				.day,
				.time,
				.type,
				.details {
					flex: 0 1 calc(100% * 1 / 12);
					width: calc(100% * 1 / 12);
				}
				.time {
					flex: 0 1 calc(100% * 1 / 12);
				}

				.job,
				.location {
					flex: 1 1 10%;
				}

				.details {
					display: flex;
					justify-content: space-evenly;
					flex-direction: row;
					max-height: 25px;

					.type_device {
						flex: 0 1 33.3%;
						width: 33.3%;
						display: flex;
						i {
							margin: auto;
							color: $colorTealLight;
							transition: 0.2s;
							cursor: pointer;
							&:hover {
								color: $colorTeal;
								transition: 0.2s;
							}
						}
						.intelli_marcaje-verificado {
							font-size: 10px;
						}
					}

					.photo {
						flex: 0 1 33.3%;
						width: 33.3%;
						display: flex;
						.image {
							width: 100%;
							img {
								height: 89%;
							}
						}
						i {
							margin: auto;
							color: $colorPrimaryLight;
							transition: 0.2s;
							cursor: pointer;
							&:hover {
								color: $colorTeal;
								transition: 0.2s;
							}
						}
					}

					.location {
						flex: 0 1 33.3%;
						display: flex;
						i {
							margin: auto;
							color: $colorAlert;
							transition: 0.2s;
							cursor: pointer;
							&:hover {
								color: $colorAlertDark;
								transition: 0.2s;
							}
						}
					}
				}
			}
		}
	}
}

.wizard-modal {
	height: 100%;
	width: 100%;
	flex: 1 1 100%;
	display: flex;
	justify-content: space-evenly;
	.option {
		flex: 0 1 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
    min-width: 105px;
		.MuiGrid-root {
			flex: 0 1 auto;
			margin-bottom: 7px;
		}
		span {
			flex: 0 1 auto;
		}
	}
}
